import React, { useState, useEffect } from "react";
import styles from "./MonthBtn.module.css";
import { TbHexagons } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
const TotalDash = ({ dashboardList, selectedMonth, perm }) => {
  const navigate = useNavigate();
  // const handleSignIn = (val) => {
  //   // console.log("val", val);
  //   navigate("/b2cAdmin/borrower-list", {
  //     state: { val, selectedMonth },
  //   });
  // };
  const handleSignIn = (val) => {
    console.log("val", val);
    if (perm.includes("read")) {
      navigate("/lender-borrower-list", {
        state: { val, selectedMonth },
      });
    } else {
      console.log("You do not have permission to view this page.");
    }
  };
  return (
    <div style={{ marginTop: "20px", display: "grid" }}>
      <div className={styles.monthBoxes}>
        {/* {applicationData.map((app, index) => ( */}
        <div className={styles.ttldv} onClick={() => handleSignIn("")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Total Application{/* {app.title} */}
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {dashboardList?.total_application}
              </p>
              <div
                style={{
                  background: "#299cdb",
                  backgroundImage: ` radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%,transparent 86.18%),radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%,rgba(255, 255, 255, 0) 69.79%,rgba(255, 255, 255, 0) 100% )`,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={25} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("1")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Disbursed Application{/* {app.title} */}
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {dashboardList?.total_disbursed_application}
              </p>
              <div
                style={{
                  background: "#299cdb",
                  backgroundImage: ` radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%,transparent 86.18%),radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%,rgba(255, 255, 255, 0) 69.79%,rgba(255, 255, 255, 0) 100% )`,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={25} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("1")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              Rejected Application
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {dashboardList?.total_rejected_application}
              </p>
              <div
                style={{
                  background: "#299cdb",
                  backgroundImage: ` radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%,transparent 86.18%),radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%,rgba(255, 255, 255, 0) 69.79%,rgba(255, 255, 255, 0) 100% )`,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={25} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("2")}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11px" }}>
              {" "}
              Disbursed Amount
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {dashboardList?.total_disbursed_loan_amount}
              </p>
              <div
                style={{
                  background: "#25a546",
                  backgroundImage: ` radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%,transparent 86.18%),radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%,rgba(255, 255, 255, 0) 69.79%,rgba(255, 255, 255, 0) 100% )`,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={25} />
              </div>
            </div>
          </div>
        </div>

        {/* ))} */}
      </div>
    </div>
  );
};

export default TotalDash;
