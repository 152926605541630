import React from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useToast } from "../../../context/ToastProvder";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch, control } = useForm();
  const { showToast } = useToast();
  const [{ data: lspList }, { data: lenderList }, { data: dealerList }] =
    useQueries({
      queries: [
        {
          queryKey: ["department"],
          queryFn: () =>
            api.get("admin/get-lsp-screener").then((res) => res.data),
          initialData: [],
        },
        {
          queryKey: ["userType"],
          queryFn: () =>
            api.get("admin/lender-screener").then((res) => res.data),
          initialData: [],
        },
        {
          queryKey: ["dealers"],
          queryFn: () => api.get("dealer-screener").then((res) => res.data),
          initialData: [],
        },
      ],
    });
  const onSubmit = (data) => {
    const { start_date, end_date } = data;

    // Check if end date is selected without a start date
    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }
    console.log("searchForm submitted:", data);
    onSearch(data);
  };
  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Start Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("start_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              End Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("end_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Borrower Id
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("borrowerId")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Loan Amount
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("loanAmount")}
            />
          </div>
        </div>
        <div className={styles.threeinputads} style={{ marginTop: "10px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Product
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("product")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer
            </label>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <ListWithSearch
                  options={dealerList?.data || []}
                  getOptionLabel={(val) => val.name || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>{`${option.name}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    dealerList?.data?.find(
                      (employee) => employee.id === value
                    ) || null
                  }
                />
              )}
              name={`dealer_id`}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              LSP
            </label>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <ListWithSearch
                  options={lspList?.data || []}
                  getOptionLabel={(val) => val.name || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>{`${option.name}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    lspList?.data?.find((employee) => employee.id === value) ||
                    null
                  }
                />
              )}
              name={`lsp_id`}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
