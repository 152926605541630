import { createBrowserRouter } from "react-router-dom";
import React, { useState } from "react";
import LoginPage from "../components/Login/Login";
import SiteLayout from "../components/Dashboard/SiteLayout";
import MainDashboad from "../components/Dashboard/MainDashboad/MainDashboad";
import RoleAccess from "../components/Dashboard/RoleAccess/RoleAccess";
import AccessPermisson from "../components/Dashboard/AccessPermisson/AccessPermisson";
import Language from "../components/Dashboard/Language/Language";
import DealersList from "../components/Dashboard/DealersList/DealersList";
import ProductType from "../components/Dashboard/ProductType/ProductType";
import ProductModalAsset from "../components/Dashboard/ProductModalAsset/ProductModalAsset";
import BatteryTypes from "../components/Dashboard/BatteryTypes/BatteryTypes";
import Manufacturer from "../components/Dashboard/Manufacturer/Manufacturer";
import ResidentType from "../components/Dashboard/ResidentType/ResidentType";
import MaritalStatus from "../components/Dashboard/MaritalStatus/MaritalStatus";
import OccupationTypes from "../components/Dashboard/OccupationTypes/OccupationTypes";
import VehicleTypes from "../components/Dashboard/VehicleTypes/VehicleTypes";
import Relations from "../components/Dashboard/Relations/Relations";
import Lenders from "../components/Dashboard/Lenders/Lenders";
import EmiDebitConsent from "../components/Dashboard/EmiDebitConsent/EmiDebitConsent";
import CreditDetailsConsent from "../components/Dashboard/CreditDetailsConsent/CreditDetailsConsent";
import OnboardingScreenContent from "../components/Dashboard/OnboardingScreenContent/OnboardingScreenContent";
import BorrowerList from "../components/Dashboard/Borrower/BorrowerList";
import ViewBorrowerDetails from "../components/Dashboard/ViewBorrowerDetails/ViewBorrowerDetails";
import LoanApllication from "../components/Dashboard/Loan Application/LoanApllication";
import ViewLoanApp from "../components/Dashboard/ViewLoanApp/ViewLoanApp";
import CreateDealer from "../components/Dashboard/CreateDealer/CreateDealer";
import AddProduct from "../components/Dashboard/AddProduct/AddProduct";
import User from "../components/Dashboard/User/User";
import UserType from "../components/Dashboard/UserType/UserType";
import DepartmentL from "../components/Dashboard/DepartmentL/DepartmentL";
import Profile from "../components/Dashboard/Profile/Profile";
import ExcelProduct from "../components/Dashboard/ExcelProduct/ExcelProduct";
import DealerAuthority from "../components/Dashboard/DealerAuthority/DealerAuthority";
import AddDealerAuthority from "../components/Dashboard/AddDealerAuthority/AddDealerAuthority";
import ViewDeDetails from "../components/Dashboard/ViewDeDetails/ViewDeDetails";
import AddLender from "../components/Dashboard/AddLender/AddLender";
import LenderAuthority from "../components/Dashboard/LenderAuthority/LenderAuthority";
import LenderServiceProvider from "../components/Dashboard/LenderServiceProvider/LenderServiceProvider";
import AddLenderServiceProvider from "../components/Dashboard/AddLenderServiceProvider/AddLenderServiceProvider";
import LenderServiceProviderAuthority from "../components/Dashboard/LenderServiceProviderAuthority/LenderServiceProviderAuthority";
import LenderExacutive from "../components/Dashboard/LenderExacutive/LenderExacutive";
import AddLenderEx from "../components/Dashboard/AddLenderEx/AddLenderEx";
import EditDealerD from "../components/Dashboard/EditDealerD/EditDealerD";
import VarifyItsYou from "../components/Dashboard/VarifyItsYou/VarifyItsYou";
import ChangePW from "../components/Dashboard/ChangePW/ChangePW";
import ViewLspDet from "../components/Dashboard/ViewLspDet/ViewLspDet";
import ApplyLoan from "../components/Dashboard/ApplyLoan/ApplyLoan";
import EditGuarantorDocuments from "../components/Dashboard/EditGuarantorDocuments/EditGuarantorDocuments";
import EditLsp from "../components/Dashboard/EditLsp/EditLsp";
import BranchLocation from "../components/Dashboard/BranchLocation/BranchLocation";
import B2BBorrower from "../components/Dashboard/Borrower/B2BBorrower";
import B2CBorrower from "../components/Dashboard/Borrower/B2CBorrower";
import AllUser from "../components/Dashboard/AllUser/AllUser";
import UserJouney from "../components/Dashboard/UserJourney/UserJouney";
import NewApplyLoan from "../components/Dashboard/ApplyLoan/NewApplyLoan";
import Disbursment from "../components/Dashboard/Disbursment/Disbursment";
import ExcelDisbursment from "../components/Dashboard/ExcelDisbursment/ExcelDisbursment";
import AppActivity from "../components/Dashboard/AppActivityLog/AppActivity";
import LenderDashboard from "../components/Dashboard/LenderDashboard/LenderDashboard";
import LenderBorrower from "../components/Dashboard/Borrower/LenderBorrower";
import OEMDealer from "../components/Dashboard/OEMDealer/OEMDealer";
import OEMList from "../components/Dashboard/OEMList/OEMList";
import AddOEM from "../components/Dashboard/AddOEM/AddOEM";
import EditOEM from "../components/Dashboard/EdiOEM/EditOEM";
import OEMDetails from "../components/Dashboard/OEMDetails/OEMDetails";
import OEMBorrwer from "../components/Dashboard/Borrower/OEMBorrwer";
import OEMProduct from "../components/Dashboard/ProductModalAsset/OEMProduct";
import OEMDashboard from "../components/Dashboard/MainDashboad/OEMDashboard";
import AddOEMProduct from "../components/Dashboard/AddOEMProduct/AddOEMProduct";
import OEMOwn from "../components/Dashboard/ProductModalAsset/OEMOwn";
import ViewProductSpecification from "../components/Dashboard/ViewProductSpecification.jsx/ViewProductSpecification";
import LenderBorrowerD from "../components/Dashboard/ViewBorrowerDetails/LenderBorrowerD";
import LenderBList from "../components/Dashboard/LenderBList/LenderBList";
import DownloadReqList from "../components/Dashboard/DownloadReqList/DownloadReqList";
import ReqLen from "../components/Dashboard/DownloadReqList/ReqLen";
import BeneficiaryList from "../components/Dashboard/BeneficiaryList/BeneficiaryList";
import LenderDealerG from "../components/Dashboard/LenderDealerG/LenderDealerG";
import SubjectList from "../components/Dashboard/SubjectList/SubjectList";
import SupportQ from "../components/Dashboard/SupportQ/SupportQ";
import OEMAddProduct from "../components/Dashboard/OEMAddProduct/OEMAddProduct";
import OEMExcel from "../components/Dashboard/ExcelProduct/OEMExcel";
import PaymentMeathod from "../components/Dashboard/PaymentMethod/PaymentMeathod";
import GenerateReceipt from "../components/Dashboard/GenrateReciept/GenerateReceipt";
import GeneratedReciept from "../components/Dashboard/GeneratedReciept/GeneratedReciept";
import Subventiondetails from "../components/Dashboard/Subvention details/Subventiondetails";
import SubventionList from "../components/Dashboard/SubventionList/SubventionList";

const routes = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "",
    element: <SiteLayout />,
    children: [
      //   { index: true, element: <Home /> },
      // { path: "*", element: <ErrorPage /> },
      // <Suspense fallback={<div>Loading...</div>}></Suspense>,
      //   <Suspense
      //     fallback={
      //       <DotLoading
      //         contStyle={{
      //           textAlign: "center",
      //           display: "flex",
      //           alignItems: "center",
      //           justifyContent: "center",
      //           minHeight: "75vh",
      //         }}
      //       />
      //     }
      //   ></Suspense>,

      {
        path: "dashboard",
        element: <MainDashboad />,
      },
      {
        path: "oem-dashboard",
        element: <OEMDashboard />,
      },
      {
        path: "role-access",
        element: <RoleAccess />,
      },
      {
        path: "access-permission",
        element: <AccessPermisson />,
      },
      {
        path: "languages",
        element: <Language />,
      },
      {
        path: "branch",
        element: <BranchLocation />,
      },
      {
        path: "dealers-list",
        element: <DealersList />,
      },
      {
        path: "product-type",
        element: <ProductType />,
      },
      {
        path: "product-list",
        element: <ProductModalAsset />,
      },
      {
        path: "oem-product-list",
        element: <OEMProduct />,
      },
      {
        path: "oem-own-products",
        element: <OEMOwn />,
      },
      {
        path: "battery-types",
        element: <BatteryTypes />,
      },
      {
        path: "manufacturer",
        element: <Manufacturer />,
      },
      {
        path: "marital-status",
        element: <MaritalStatus />,
      },
      {
        path: "resident-type",
        element: <ResidentType />,
      },
      {
        path: "occupation-types",
        element: <OccupationTypes />,
      },
      {
        path: "vehicle-types",
        element: <VehicleTypes />,
      },
      {
        path: "relations",
        element: <Relations />,
      },
      {
        path: "lenders",
        element: <Lenders />,
      },

      {
        path: "emi-debit-consent",
        element: <EmiDebitConsent />,
      },

      {
        path: "credit-details-consent",
        element: <CreditDetailsConsent />,
      },
      {
        path: "onboarding-screen-content",
        element: <OnboardingScreenContent />,
      },
      {
        path: "borrower-list",
        element: <BorrowerList />,
      },
      {
        path: "oem-borrower-list",
        element: <OEMBorrwer />,
      },
      {
        path: "lender-borrower-list",
        element: <LenderBorrower />,
      },
      {
        path: "b2c-borrower-list",
        element: <B2CBorrower />,
      },
      {
        path: "b2b-borrower-list",
        element: <B2BBorrower />,
      },
      {
        path: "view-borrower-details",
        element: <ViewBorrowerDetails />,
      },
      {
        path: "view-lender-borrower-details",
        element: <LenderBorrowerD />,
      },
      {
        path: "loan-application-list",
        element: <LoanApllication />,
      },
      {
        path: "view-loan-details",
        element: <ViewLoanApp />,
      },

      {
        path: "create-dealer",
        element: <CreateDealer />,
      },
      {
        path: "edit-dealer",
        element: <EditDealerD />,
      },
      {
        path: "add-product",
        element: <AddProduct />,
      },
      {
        path: "department",
        element: <DepartmentL />,
      },
      {
        path: "user-type",
        element: <UserType />,
      },
      {
        path: "user",
        element: <User />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "excel-product-list",
        element: <ExcelProduct />,
      },

      {
        path: "dealer-authority",
        element: <DealerAuthority />,
      },
      {
        path: "add-dealer-authority",
        element: <AddDealerAuthority />,
      },
      {
        path: "view-dealer-details",
        element: <ViewDeDetails />,
      },
      {
        path: "add-lender",
        element: <AddLender />,
      },
      {
        path: "lender-authority",
        element: <LenderAuthority />,
      },
      {
        path: "loan-service-provider",
        element: <LenderServiceProvider />,
      },
      {
        path: "add-loan-service-provider",
        element: <AddLenderServiceProvider />,
      },
      {
        path: "edit-loan-service-provider",
        element: <EditLsp />,
      },
      {
        path: "loan-service-provider-authority",
        element: <LenderServiceProviderAuthority />,
      },
      {
        path: "lsp-executive",
        element: <LenderExacutive />,
      },
      {
        path: "add-lsp-executive",
        element: <AddLenderEx />,
      },

      {
        path: "verify-user",
        element: <VarifyItsYou />,
      },
      {
        path: "change-password",
        element: <ChangePW />,
      },
      {
        path: "lsp-details",
        element: <ViewLspDet />,
      },
      {
        path: "apply-loan",
        element: <ApplyLoan />,
      },
      {
        path: "apply-loan-application",
        element: <NewApplyLoan />,
      },

      {
        path: "edit-documents",
        element: <EditGuarantorDocuments />,
      },
      {
        path: "all-user-list",
        element: <AllUser />,
      },
      {
        path: "user-journey",
        element: <UserJouney />,
      },
      {
        path: "disbursement-details",
        element: <Disbursment />,
      },
      {
        path: "excel-disbursement-upload",
        element: <ExcelDisbursment />,
      },
      {
        path: "app-activity-log",
        element: <AppActivity />,
      },
      {
        path: "lender-dashboard",
        element: <LenderDashboard />,
      },
      {
        path: "oem-dealer-list",
        element: <OEMDealer />,
      },
      {
        path: "oem-list",
        element: <OEMList />,
      },

      {
        path: "add-oem",
        element: <AddOEM />,
      },
      {
        path: "edit-oem",
        element: <EditOEM />,
      },
      {
        path: "oem-details",
        element: <OEMDetails />,
      },
      {
        path: "product-specification",
        element: <ViewProductSpecification />,
      },
      {
        path: "add-product-specification",
        element: <AddOEMProduct />,
      },

      {
        path: "lender-borrower-group-list",
        element: <LenderBList />,
      },

      {
        path: "lender-dealer-group-list",
        element: <LenderDealerG />,
      },
      {
        path: "download-request-list",
        element: <DownloadReqList />,
      },
      {
        path: "lender-download-request-list",
        element: <ReqLen />,
      },
      {
        path: "beneficiary-list",
        element: <BeneficiaryList />,
      },
      {
        path: "support-subject-list",
        element: <SubjectList />,
      },
      {
        path: "support-queries-list",
        element: <SupportQ />,
      },
      {
        path: "add-oem-product",
        element: <OEMAddProduct />,
      },
      {
        path: "oem-product-excel",
        element: <OEMExcel />,
      },
      {
        path: "payment-method",
        element: <PaymentMeathod />,
      },
      {
        path: "generate-receipt",
        element: <GenerateReceipt />,
      },
      {
        path: "generated-receipts",
        element: <GeneratedReciept />,
      },
      {
        path: "add-subvention-details",
        element: <Subventiondetails />,
      },
      {
        path: "subvention-details-list",
        element: <SubventionList />,
      },
    ],
  },
]);
export default routes;
