import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaEye, FaSort } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import profl from "./../../../icons/profl.jpg";
import { IoDocuments } from "react-icons/io5";
import AddModal from "./AddModal";
import { TbPlayerTrackNext } from "react-icons/tb";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };

  const navigate = useNavigate();
  const handleSignIn = (row) => {
    navigate("/apply-loan", {
      state: { row },
    });
  };
  const handleDocIn = (borrowerId) => {
    navigate("/edit-documents", {
      state: { borrowerId },
    });
  };
  console.log("rowData", rowData);
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      {" "}
      {rowToEdit && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
      )}
      <div className={styles.tabwrap}>
        {" "}
        {rowData?.data && rowData?.data.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#12468a",
                backgroundImage:
                  "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th style={{ minWidth: "40px" }}>#</th>
                <th
                  className={styles.tabforstic}
                  style={{
                    backgroundColor: "#12468a",
                    backgroundImage:
                      "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                    minWidth: "140px",
                  }}
                >
                  User Name
                </th>

                <th style={{ minWidth: "120px" }}>Borrower Id</th>
                <th style={{ minWidth: "120px" }}>Mobile No.</th>
                <th style={{ minWidth: "170px" }}>Alternative Mobile No.</th>
                <th style={{ minWidth: "120px" }}>Email</th>
                <th style={{ minWidth: "120px" }}>Last Step</th>

                <th style={{ minWidth: "140px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.data?.map((row, index) => (
                <tr
                  key={row?.id}
                  // style={{
                  //   backgroundColor:
                  //     row.loan_status === "REJECTED" ? "#ffa0a0" : "inherit",
                  // }}
                >
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      position: "sticky",
                      left: "0",
                      background: "#fff",
                    }}
                  >
                    {row.borrower_name && (
                      <img
                        src={profl}
                        style={{
                          borderRadius: "100%",
                          width: "30px",
                        }}
                      />
                    )}
                    <span
                      style={{
                        position: "relative",
                        bottom: "10px",
                        paddingLeft: "10px",
                      }}
                    >
                      {row.borrower_name}
                    </span>
                  </td>
                  <td>{row.borrower_unq}</td>
                  <td>{row.mobile_no}</td>
                  <td>{row.alternate_number1}</td>
                  <td>{row.email_id}</td>
                  <td>{row?.last_step?.details}</td>

                  <td>
                    {row?.last_step?.application_step !== "28" &&
                      perm?.includes("update") && (
                        <button
                          type="button"
                          title="Continue"
                          className={styles.btnn_3}
                          onClick={() => handleSignIn(row)}
                        >
                          <TbPlayerTrackNext
                            size={12}
                            style={{ position: "relative", top: "1px" }}
                          />
                        </button>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
      </div>
    </div>
  );
};

export default Table;
