import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
const AddModal = ({ addHandle, defaultValue, val }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm();
  const password = watch("password");
  const [err, setErr] = useState({});
  useEffect(() => {
    if (defaultValue) {
      setValue("name", defaultValue.name);
      setValue("mobile", defaultValue.mobile);
      setValue("email", defaultValue.email);
    }
  }, [defaultValue, setValue]);
  const [{ data: depatmentList }, { data: userTypeList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["userType"],
        queryFn: () =>
          api.get("get-user-type-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  console.log("val", val);
  const onSubmit = async (data) => {
    let payload;
    if (defaultValue) {
      payload = {
        name: data.name,
        mobile: data.mobile,
        email: data.email,
      };
    } else {
      payload = {
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        lender_id: val?.id,
      };
    }
    // console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };
  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `admin/update-lender-director/${defaultValue.id}`
      : "admin/store-lender-directors",
    method: defaultValue ? "put" : "post",
    invalidateKey: "userlist",
    successCallback: () => {
      reset();
      addHandle();
    },
    setErr: setErr,
  });
  console.log("Err", err);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "520px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Edit Lender Director " : "Add Lender Director "}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Name
            </lebel>
            <input
              {...register("name", { required: true })}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.name && (
              <span className={styles.error}>This Field is Required.</span>
            )}
            {/* <span className={styles.error}>{err.name ? err.name[0] : ""}</span> */}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Mobile
            </lebel>
            <input
              {...register("mobile", {
                required: true,
                minLength: {
                  value: 10,
                  message: "10 digits Required",
                },
              })}
              type="number"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.mobile && (
              <span className={styles.error}>
                {errors.mobile.message || "This Field is Misiing"}
              </span>
            )}
            {/* <span className={styles.error}>
              {err.mobile ? err.mobile[0] : ""}
            </span> */}
          </div>
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Email
            </lebel>
            <input
              {...register("email", { required: true })}
              type="email"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.email && (
              <span className={styles.error}>{"This Field is Missing."}</span>
            )}
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
