import React, { useContext, useEffect, useState } from "react";
import classes from "../Borrower/Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import Table from "./Table";
import PaginationComponent from "../../Common/PaginationComponent";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import api from "../../Common/api";
import Loader from "../../Common/Loader";
import SearchComp from "./SearchComp";
import { AuthContext } from "../../../context/AuthContext";
const AllUser = () => {
  const [page, setPage] = useState(1);
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [step, setStep] = useState(null);
  const [product, setProduct] = useState(null);
  const [status, setStatus] = useState(null);
  const [month, setMonth] = useState(null);
  const [perm, setPerm] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const ShowSearchHandler = (event, value) => {
    setShowSearch((val) => !val);
  };
  const { activeSubmenuId } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [searchData, setSearchData] = useState(null);
  const location = useLocation();
  const { val, selectedMonth } = location.state || "";
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (selectedMonth) {
      setMonth(selectedMonth);
    }
  }, [selectedMonth]);
  useEffect(() => {
    if (val) {
      setStep(val);
    }
  }, [val]);

  const fetchData = async ({
    page,
    numberOfRecords,
    searchData,
    step,
    month,
    status,
    typ,
  }) => {
    const payload = {
      page,
      number_of_records: numberOfRecords,
    };

    if (searchData) {
      payload.start_date = searchData.start_date || "";
      payload.end_date = searchData.end_date || "";
      payload.borrower_name = searchData.borrower_name || "";
      payload.borrower_id = searchData.borrower_id || "";
      payload.mobile_no = searchData.mobile_no || "";
      payload.pan_no = searchData.pan_no || "";
      payload.aadhar_no = searchData.aadhar_no || "";
      payload.dob = searchData.dob || "";
      payload.application_date = searchData.application_date || "";
      payload.status = searchData.status || "";
    }

    const response = await api.get("admin/get-all-user-list", {
      params: payload,
    });
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: [
      "borrowerList",
      page,
      numberOfRecords,
      searchData,
      step,
      month,
      status,

      product,
    ],
    queryFn: () =>
      fetchData({
        page,
        numberOfRecords,
        searchData,
        step,
        month,
        status,

        product,
      }),
    onSuccess: (data) => {
      console.log("on succ data", data);
      setTotalPage(data.data?.last_page);
    },
  });

  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;

  const searchDataHandler = (data) => {
    setSearchData(data);
    setPage(1);
  };
  const handleRecordsChange = (event) => {
    setNumberOfRecords(event.target.value);
  };

  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-sub-menu-permissions/${activeSubmenuId}`
    );
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions", activeSubmenuId],
    queryFn: fetchPermissions,
    enabled: !!activeSubmenuId,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;

  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      {perm?.includes("read") && (
        <div className={classes.mainBodyContainer}>
          <Breadcum pageName="All User List" />
          <div className={classes["main-body"]} style={{ padding: "15px" }}>
            <div className={classes.tablco}>
              {showSearch && <SearchComp onSearch={searchDataHandler} />}

              <div className={classes.inputsearchcon}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <select
                    onChange={handleRecordsChange}
                    value={numberOfRecords}
                    className={classes.selectfotnu}
                    style={{ width: "55px" }}
                  >
                    {["10", "20", "50", "100"].map((value) => (
                      <option
                        key={value}
                        style={{ fontSize: "11px" }}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    type="button"
                    className={classes.gobtn}
                    onClick={ShowSearchHandler}
                  >
                    <FaSearch
                      className={classes.gobtnicon}
                      size={12}
                      style={{ borderRadius: "5px", padding: "5px" }}
                    />
                  </button>
                </div>
              </div>
              <div className={classes.tableContai}>
                {isLoading || isFetching ? (
                  <Loader />
                ) : (
                  <Table
                    rowData={dashboardList?.data || []}
                    page={page}
                    numberOfRecords={numberOfRecords}
                    perm={perm}
                  />
                )}
              </div>
              <div className={classes.paginCont}>
                <p className={classes.pagitext}>
                  Showing {dashboardList?.data?.from} to{" "}
                  {dashboardList?.data?.to} of {dashboardList?.data?.total}{" "}
                  entries.
                </p>
                <PaginationComponent
                  pageChangeHandler={handleChange}
                  pageState={page}
                  totalPageNo={totalPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllUser;
