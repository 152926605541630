import React, { useEffect, useState } from "react";
// import styles from "./Modal.module.css";
import styles from "../UserType/Modal.module.css";
import { Controller, useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
import ListWithSearc from "../../Common/ListWithSearch/ListWithSearc";
const Disbusment = ({ addHandle, defaultValue }) => {
  console.log("defaultValue dis", defaultValue);
  return (
    <div
      className={styles.modalcontainer}
      style={{ width: "520px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Disbursement Details</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              UTR Number
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                width: "225px",
              }}
            >
              {defaultValue?.utr_number}
            </lebel>

            {/* <span className={styles.error}>
              {err?.name ? err?.name[0] : ""}
            </span> */}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Disbursement Amount
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                width: "225px",
              }}
            >
              {defaultValue?.disbursement_amount}
            </lebel>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Disbursement Date
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                width: "225px",
              }}
            >
              {defaultValue?.disbursement_date}
            </lebel>
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Loan ID
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                width: "225px",
              }}
            >
              {defaultValue?.loan_id}
            </lebel>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "40px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.searorinpzrssw}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Transaction ID
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                width: "225px",
              }}
            >
              {defaultValue?.transaction_id}
            </lebel>
          </div>

          <div className={styles.searorinpzrssw}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Lender
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                width: "225px",
              }}
            >
              {defaultValue?.lender_name}
            </lebel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disbusment;
