import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaEye, FaSort } from "react-icons/fa";
// import ViewCharges from "./ViewCharges";
import {
  MdDelete,
  MdOutlineAddCircleOutline,
  MdPlaylistAddCheckCircle,
} from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
// import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
// import AddCharges from "./AddCharges";
import CustomActInBox from "../../Common/Custom Confirm/CustomActInBox";
import useCustomMutation from "../../CustomHook/useCustomMutation";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [char, setChar] = useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };
  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  console.log("rowData", rowData);
  const navigate = useNavigate();

  const handleEdit = (val) => {
    console.log("val", val);
    navigate("/edit-dealer", {
      state: { val },
    });
  };

  const handleView = (val) => {
    console.log("val", val);
    navigate("/dealer-authority", {
      state: { val },
    });
  };
  const updateDelMutation = useCustomMutation({
    url: `admin/update-dealer-status/${selectedItem?.id}`,
    method: "get",
    invalidateKey: "langList",
    // setErr: setErr,
    successCallback: () => {
      // reset();
      // addHandle();
    },
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteHandler = (data) => {
    setSelectedItem(data);
    handleClickOpen();
    console.log(data);
  };

  const deleteConfirmHandler = () => {
    console.log("selectedData", selectedItem);
    updateDelMutation.mutate(selectedItem);
    handleClose();
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(75vh - 220px)" }}
    >
      {" "}
      {open && (
        <CustomActInBox
          data={selectedItem}
          onCancel={handleClose}
          onConfirm={deleteConfirmHandler}
          message={`Are you sure you want to ${
            selectedItem.is_active ? "deactivate" : "activate"
          } ${selectedItem.name}`}
          open={open}
        />
      )}
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>#</th>

                <th> Borrower ID</th>
                <th> Borrower Name</th>
                <th> Lender</th>
                <th> Download Left For Borrower Document</th>
                <th> Download Left For Agreement</th>
                <th> Download Left For Cam Sheet</th>
                <th> Status</th>
                <th>Modified By</th>
                <th>Modified At</th>
                {/* 
                <th> Action</th> */}
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr key={row.id}>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.borrower_unq}</td>
                  <td>{row.borrower_name}</td>
                  <td>{row?.lender?.name}</td>
                  <td>{row?.borrower_doc_download_left}</td>
                  <td>{row?.agreement_download_left}</td>
                  <td>{row?.cam_sheet_download_left}</td>
                  <td>{row?.status}</td>
                  <td>{row?.modified_by?.name}</td>
                  <td>{row?.updated_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
      </div>
    </div>
  );
};

export default Table;
