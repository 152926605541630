import React from "react";
import { useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch } = useForm();
  const onSubmit = (data) => {
    console.log("searchForm submitted:", data);
    onSearch(data);
  };
  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const [{ data: departmentList }] = useQueries({
    queries: [
      {
        queryKey: ["branchlist"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Beneficiary Name
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("beneficiary_name")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Account Number
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("account_number")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              IFSC Code
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("ifsc_code")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              MICR Code
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("micr_code")}
            />
          </div>
        </div>
        <div
          className={styles.twoinputasc}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          {" "}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Status
            </label>

            <select
              {...register("status")}
              className={styles.inpttsc}
              style={{ height: "29px" }}
            >
              <option value="">--Select Status--</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              User Type
            </label>

            <select
              {...register("user_type")}
              className={styles.inpttsc}
              style={{ height: "29px" }}
            >
              <option value="">--Select User Type--</option>
              <option value="DEALER">Dealer</option>
              <option value="OEM">OEM</option>
            </select>
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
