import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaSort } from "react-icons/fa";
import {
  MdDelete,
  MdOutlineAddCircleOutline,
  MdPlaylistAddCheckCircle,
} from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
import AddCharges from "./AddCharges";
import ViewCharges from "./ViewCharges";
const OEMTable = ({ rowData, page, numberOfRecords, perm, type }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [char, setChar] = useState(false);
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };
  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const navigate = useNavigate();
  const handleEditRow = (val) => {
    navigate("/add-product", {
      state: { val },
    });
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#12468a",
                backgroundImage:
                  "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 20%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th style={{ minWidth: "40px" }}>#</th>
                <th>OEM Product ID</th>
                <th style={{ minWidth: "120px" }}>Product Type</th>
                <th style={{ minWidth: "120px" }}>Product Name</th>
                <th style={{ minWidth: "120px" }}>Battery Type</th>
                <th style={{ minWidth: "120px" }}>Ex-showroom Price</th>
                <th style={{ minWidth: "120px" }}>Loan to Value Ratio</th>
                <th style={{ minWidth: "120px" }}>Min Loan Amount</th>
                <th style={{ minWidth: "120px" }}>Max Loan Amount</th>
                <th style={{ minWidth: "120px" }}>Min Loan Tenure</th>
                <th style={{ minWidth: "120px" }}>Max Loan Tenure</th>
                <th style={{ minWidth: "120px" }}>Interest Rate</th>
                <th style={{ minWidth: "120px" }}>Processing Fee</th>
                <th style={{ minWidth: "120px" }}>Subvention Amount</th>
                <th style={{ minWidth: "120px" }}>FLDG Amount</th>
                <th style={{ minWidth: "120px" }}>IOT Amount</th>
                <th style={{ minWidth: "120px" }}>1st Year Insurance</th>
                <th style={{ minWidth: "120px" }}>2nd Year Insurance</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>

                  <td>{row.oem_product_unq}</td>
                  <td>{row.product_type}</td>
                  <td>{row.product_name}</td>
                  <td>{row.battery_type}</td>
                  <td>{row.ex_showroom_price}</td>
                  <td>{row.loan_to_value_ratio}</td>
                  <td>{row.min_loan_amount}</td>
                  <td>{row.max_loan_amount}</td>
                  <td>{row.min_loan_tenure}</td>
                  <td>{row.max_loan_tenure}</td>
                  <td>{row.interest_rate}</td>
                  <td>{row.processing_fee}</td>
                  <td>{row.subvention_amount}</td>
                  <td>{row.fldg_amount}</td>
                  <td>{row.iot_amount}</td>
                  <td>{row.insurance_1st_year}</td>
                  <td>{row.insurance_2nd_year}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
        {char && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={char}
            onClick={addCharHandle}
          >
            <AddCharges
              addHandle={addCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
        {viewChar && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={viewChar}
            onClick={ViewCharHandle}
          >
            <ViewCharges
              addHandle={ViewCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
      </div>
    </div>
  );
};

export default OEMTable;
