import React, { useContext, useEffect } from "react";
import styles from "../CreateDealer/BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { Document, Page } from "react-pdf";
import { useState } from "react";
import { Dialog } from "@mui/material";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
// import ViewImg from "./ViewImg";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import ViewImg from "../OEMDetails/ViewImg";
import ZoomImg from "../EditGuarantorDocuments/ZoomImg";
const ViewDet = ({ defaultValue }) => {
  const [add, setAdd] = useState(false);
  const { token, baseURL, baseDoc } = useContext(AuthContext);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [bimg, setBimg] = useState(false);
  const [zoomImgUrl, setZoomImgUrl] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const [modalFocused, setModalFocused] = useState(true);

  const handleFocus = () => setModalFocused(true);
  const handleBlur = () => setModalFocused(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const [open, setOpen] = React.useState(false);

  console.log("existingData", existingData);
  const [pdfUrl, setPdfUrl] = React.useState("");
  const disableRightClick = (e) => {
    e.preventDefault();
  };
  const getFullUrl = (path) => (path ? `${baseDoc}${path}` : null);

  // useEffect(() => {
  //   document.addEventListener("contextmenu", disableRightClick);
  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //   };
  // }, []);
  const handleViewPdf = (url) => {
    const fullUrl = `${baseDoc}${url}`;
    setPdfUrl(fullUrl);
    setOpen(true);
  };

  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    pointerEvents: "none",
  };
  const imgHandle = (url) => {
    setZoomImgUrl(url);
    setBimg((val) => !val);
  };
  console.log("rec url", zoomImgUrl);
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={bimg}
        onClick={imgHandle}
      >
        <ZoomImg
          addHandle={imgHandle}
          zoomImgUrl={zoomImgUrl}
          // borrowerId={borrowerId}
        />
      </Backdrop>
      <div>
        <div className={styles.totalcheckconnn} style={{ marginTop: "10px" }}>
          {/* <div className={styles.fourinputasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Motor
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {defaultValue?.motor}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Battery
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {defaultValue?.battery}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Controller
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {defaultValue?.controller}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Tyre
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {defaultValue?.tyre}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Performance
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {defaultValue?.performance}
            </label>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Created At
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
              }}
            >
              {defaultValue?.created_at}
            </label>
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Brochure
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {defaultValue && defaultValue?.brochure && (
                <button
                  type="button"
                  // onClick={() => handleViewPdf(existingData.pan)}
                  onClick={() => imgHandle(getFullUrl(defaultValue.brochure))}
                  className={styles.bbtfnvogfde}
                >
                  View
                </button>
              )}
            </label>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          <button
            onClick={() => setOpen(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 10,
              backgroundColor: "white",
              border: "none",
              cursor: "pointer",
              padding: "5px 10px",
              borderRadius: "5px",
              width: "300px",
            }}
          >
            Close
          </button>

          <iframe
            src={pdfUrl}
            style={{
              width: "100%",
              height: "2000px",
              border: "none",
              pointerEvents: "none",
            }}
          />

          <div style={overlayStyle} />
        </div>
      </Dialog>
    </div>
  );
};

export default ViewDet;
