import React, { useContext, useEffect } from "react";
import styles from "../CreateDealer/BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop, CircularProgress } from "@mui/material";
import ViewImg from "./ViewImg";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import IFSCDetails from "../BeneficiaryList/IFSCDetails";
const DealerForm = ({ defaultValue }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [dataFromChe, setDataFromChe] = useState(null);
  const [ifscD, setIfscD] = useState(false);
  const [loading, setLoading] = useState(false);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const { token, baseURL } = useContext(AuthContext);

  const MobNo = watch("mobile");
  const EmailNo = watch("email");
  const fetchData = async () => {
    const response = await api.get(`admin/edit-oem-details/${defaultValue.id}`);
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;

  const companyType = watch("company_type");

  const addifsc = () => {
    setIfscD((val) => !val);
  };
  const handleifscRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setIfscD((val) => !val);
  };
  useEffect(() => {
    if (dataFromChe) {
      setValue("account_number", dataFromChe?.account_number);

      setValue("ifsc_code", dataFromChe?.ifsc_code);
      setValue("micr_code", dataFromChe?.micr_code);
    }
  }, [dataFromChe, setValue]);
  useEffect(() => {
    if (existingData) {
      setValue("name", existingData?.manufacturer);
      setValue("date_of_incorporation", existingData?.date_of_incorporation);
      setValue("mobile", existingData?.mobile);
      setValue("beneficiary_name", existingData?.beneficiary_name);
      setValue("account_number", existingData?.account_number);
      setValue("ifsc_code", existingData?.ifsc_code);
      setValue("micr_code", existingData?.micr_code);
      setValue(
        "alternate_mobile_number",
        existingData?.alternate_mobile_number
      );
      setValue("email", existingData?.email);
      setValue("pan_number", existingData?.pan_number);

      setValue("landmark", existingData?.landmark);
      setValue("city", existingData?.city);
      setValue("pin", existingData?.pin);
      setValue("address_line_1", existingData?.address_line_1);
      setValue("address_line_2", existingData?.address_line_2);
      setValue("company_type", existingData?.company_type);
    }
  }, [existingData, setValue]);
  console.log("existingData", existingData);
  const onSubmit = async (data) => {
    const formData = new FormData();
    const fileInput = document.querySelector('input[name="cheque_1"]');
    const file = fileInput?.files[0];

    // Check if file is uploaded and OCR verification data is missing
    if (file && !dataFromChe) {
      showToast(
        "Please verify the cheque image before submitting the form.",
        "error"
      );
      return;
    }
    formData.append("name", data.name);
    formData.append("date_of_incorporation", data.date_of_incorporation);
    formData.append("mobile", data.mobile);
    formData.append("alternate_mobile_number", data.alternate_mobile_number);
    formData.append("email", data.email);
    formData.append("pan_number", data.pan_number);

    formData.append("landmark", data.landmark);
    formData.append("city", data.city);
    formData.append("pin", data.pin);
    formData.append("address_line_1", data.address_line_1);
    formData.append("address_line_2", data.address_line_2);
    formData.append("account_number", data.account_number);
    formData.append("beneficiary_name", data.beneficiary_name);
    formData.append("ifsc_code", data.ifsc_code);
    formData.append("micr_code", data.micr_code);
    formData.append("company_type", data.company_type);
    if (defaultValue && defaultValue.id) {
      formData.append("id", defaultValue.id);
    }

    const fileFields = [
      "bank_statement",

      "gst_certificate",
      "cheque_1",
      "cheque_2",
      "cheque_3",
      "cheque_4",
      "rent_agreement",
      "icat_certificate",
      "oem_finayo_agreement",

      "office_image",

      "itr_file",
      "pan_doc",

      "balance_sheet",

      "utility_bill",

      "partnership_deed",

      "moa",
      "aoa",
      "certificate_of_incorporation",
    ];

    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }

    try {
      const apiEndpoint = defaultValue
        ? `${baseURL}admin/update-oem-details`
        : `${baseURL}admin/update-oem-details`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      navigate("/oem-list");
    } catch (error) {
      const { message, errors } = error.response.data;

      setErr(message);
      showToast(message, "error");
    }
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };

  useEffect(() => {
    if (existingData) {
      const fileFields = [
        "gst_certificate",

        "oem_finayo_agreement",

        "office_image",

        "utility_bill",

        "pan_doc",
      ];

      fileFields.forEach((field) => {
        if (!existingData[field]) {
          register(field, { required: true });
        } else {
          register(field);
        }
      });
    }
  }, [register, existingData]);
  console.log("companyType", companyType);
  useEffect(() => {
    if (companyType === "PARTNERSHIP") {
      if (existingData?.partnership_deed) {
        register("partnership_deed");
      } else {
        register("partnership_deed", { required: true });
      }
    } else {
      register("partnership_deed");
    }
  }, [companyType, register, existingData]);
  const handleSQChange = async (event) => {
    const file = event.target.files[0];
    if (!MobNo || !EmailNo) {
      showToast("Mobile number and email are required", "error");
      event.target.value = null;
      return;
    }
    if (file) {
      const formData = new FormData();

      formData.append("cheque_image", file);
      formData.append("user_type", "OEM");
      formData.append("email", EmailNo);
      formData.append("mobile", MobNo);
      setLoading(true);
      if (defaultValue && defaultValue.id) {
        formData.append("user_id", defaultValue.id);
      }

      try {
        const apiEndpoint = `${baseURL}admin/verify-admin-cheque-ocr`;
        const response = await axios.post(apiEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        const { status, message } = response.data;
        showToast(message, status === "success" ? "success" : "error");

        setDataFromChe(response.data?.data);
      } catch (error) {
        const { message } = error.response.data;
        showToast(message, "error");
      } finally {
        setLoading(false); // Stop loader
      }
    }
  };

  useEffect(() => {
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.balance_sheet) {
        register("balance_sheet");
      } else {
        register("balance_sheet");
      }
    } else {
      register("balance_sheet");
    }
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.moa) {
        register("moa");
      } else {
        register("moa", { required: true });
      }
    } else {
      register("moa");
    }
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.aoa) {
        register("aoa");
      } else {
        register("aoa", { required: true });
      }
    } else {
      register("aoa");
    }
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.certificate_of_incorporation) {
        register("certificate_of_incorporation");
      } else {
        register("certificate_of_incorporation", { required: true });
      }
    } else {
      register("certificate_of_incorporation");
    }
  }, [companyType, register, existingData]);
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading} // Show loader when loading
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "10px" }}>
        <div className={styles.totalcheckconnn}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Company Type<span className={styles.requ}>*</span>
            </label>
            <select
              style={{ height: "26px" }}
              className={styles.inputstst}
              {...register("company_type", {
                required: true,
              })}
            >
              <option value="">--Select Company Type--</option>
              <option value="PROPRIETORSHIP">PROPRIETORSHIP</option>
              <option value="PARTNERSHIP">PARTNERSHIP</option>
              <option value="PRIVATE LIMITED">PRIVATE LIMITED</option>
            </select>
            {errors.company_type && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Name<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Name"
              type="text"
              className={styles.inputstst}
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date Of Incorporation<span className={styles.requ}>*</span>
              </label>
              <input
                type="date"
                className={styles.inputstst}
                {...register("date_of_incorporation", { required: true })}
              />
              {errors.date_of_incorporation && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile Number<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Mobile Number"
              type="number"
              className={styles.inputstst}
              {...register("mobile", {
                required: true,
                minLength: {
                  value: 10,
                  message: "Please Enter 10 digits.",
                },
              })}
            />
            {errors.mobile && (
              <span className={styles.error}>
                {errors.mobile.message || "This Field is Missing."}
              </span>
            )}
            <span className={styles.error}>{err.mobile ? err.mobile : ""}</span>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Alternate Mobile Number
            </label>
            <input
              placeholder="Enter Alternate Mobile Number"
              type="number"
              className={styles.inputstst}
              {...register("alternate_mobile_number", {
                minLength: {
                  value: 10,
                  message: "Please Enter 10 digits.",
                },
              })}
            />

            {errors.alternate_mobile_number && (
              <span className={styles.error}>
                {errors.alternate_mobile_number.message ||
                  "This Field is Required."}
              </span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Email<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Email"
              type="email"
              className={styles.inputstst}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
            <span className={styles.error}>{err.email ? err.email : ""}</span>
          </div>
        </div>
        <div className={styles.totalcheckconnn} style={{ marginTop: "15px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Address Line 1<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Address Line 1"
              type="text"
              className={styles.inputstst}
              {...register("address_line_1", { required: true })}
            />
            {errors.address_line_1 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Address Line 2<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Address Line 2"
              type="text"
              className={styles.inputstst}
              {...register("address_line_2", { required: true })}
            />
            {errors.address_line_2 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              City<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter City"
              type="text"
              className={styles.inputstst}
              {...register("city", { required: true })}
            />
            {errors.city && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Pincode<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter pincode"
              type="number"
              className={styles.inputstst}
              {...register("pin", {
                required: true,
                pattern: {
                  value: /^\d{6}$/,
                  message: "Please Enter Valid pincode",
                },
              })}
            />
            {errors.pin && (
              <span className={styles.error}>
                {errors.pin.message || "This Field is Missing."}
              </span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Landmark
            </label>
            <input
              placeholder="Enter Landmark"
              type="text"
              className={styles.inputstst}
              {...register("landmark")}
            />
            {errors.landmark && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN No.<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter PAN Number"
              type="text"
              className={styles.inputstst}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              {...register("pan_number", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9]{10}$/,
                  message: "Please Enter Valid PAN Number",
                },
              })}
            />
            {errors.pan_number && (
              <span className={styles.error}>
                {errors.pan_number.message || "This Field is Missing."}
              </span>
            )}

            <span className={styles.error}>
              {err.pan_number ? err.pan_number : ""}
            </span>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Upload PAN <span className={styles.requ}>*</span>
              {existingData && existingData?.pan_doc && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.pan_doc)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              className={styles.uploadbx}
              {...register("pan_doc")}
              type="file"
            />

            {errors.pan_doc && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              OEM Finayo Agreement<span className={styles.requ}>*</span>
              {existingData && existingData?.oem_finayo_agreement && (
                <button
                  type="button"
                  onClick={() =>
                    handleEditRow(existingData.oem_finayo_agreement)
                  }
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("oem_finayo_agreement")}
              accept="application/pdf"
            />

            {errors.oem_finayo_agreement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Bank Statement
              {existingData && existingData?.bank_statement && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.bank_statement)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              className={styles.uploadbx}
              {...register("bank_statement")}
              type="file"
              accept="application/pdf"
            />

            {errors.bank_statement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 1
              {existingData && existingData?.cheque_1 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.cheque_1)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("cheque_1")}
              onChange={handleSQChange}
              accept="image/jpeg, image/png,image/jpg"
            />
            {errors.cheque_1 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {(existingData?.beneficiary_name || dataFromChe) && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Beneficiary Name<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                {...register("beneficiary_name", { required: true })}
                className={styles.inputstst}
              />
              {errors.beneficiary_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {(existingData?.account_number || dataFromChe) && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Account Number<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                {...register("account_number", { required: true })}
                className={styles.inputstst}
                disabled
              />
              {errors.account_number && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {(existingData?.ifsc_code || dataFromChe) && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                IFSC Code<span className={styles.requ}>*</span>{" "}
                {dataFromChe?.ifsc_code && (
                  <button
                    onClick={() => handleifscRow(dataFromChe)}
                    type="button"
                    className={styles.bbtnvofde}
                  >
                    IFSC Details
                  </button>
                )}
              </label>
              <input
                {...register("ifsc_code", { required: true })}
                type="text"
                disabled
                className={styles.inputstst}
              />
              {errors.ifsc_code && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {(existingData?.micr_code || dataFromChe) && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                MICR Code<span className={styles.requ}>*</span>{" "}
              </label>
              <input
                {...register("micr_code", { required: true })}
                type="text"
                className={styles.inputstst}
                disabled
              />
              {errors.micr_code && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 2
              {existingData && existingData?.cheque_2 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.cheque_2)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("cheque_2")}
              accept="image/jpeg, image/png,image/jpg"
            />
            {errors.cheque_2 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 3{" "}
              {existingData && existingData?.cheque_3 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.cheque_3)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("cheque_3")}
              accept="image/jpeg, image/png,image/jpg"
            />
            {errors.cheque_3 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 4{" "}
              {existingData && existingData?.cheque_4 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.cheque_4)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("cheque_4")}
              accept="image/jpeg, image/png,image/jpg"
            />
            {errors.cheque_4 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              GST Certificate<span className={styles.requ}>*</span>{" "}
              {existingData && existingData?.gst_certificate && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.gst_certificate)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("gst_certificate")}
              accept="application/pdf"
            />
            {errors.gst_certificate && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Rent Agreement
              {existingData && existingData?.rent_agreement && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.rent_agreement)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("rent_agreement")}
              accept="application/pdf"
            />
            {errors.rent_agreement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              ICAT Certificate{" "}
              {existingData && existingData?.icat_certificate && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.icat_certificate)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("icat_certificate")}
              accept="application/pdf"
            />
            {errors.icat_certificate && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Office Premise Image<span className={styles.requ}>*</span>{" "}
              {existingData && existingData?.office_image && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.office_image)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("office_image")}
              accept="image/jpeg, image/png,image/jpg"
            />
            {errors.office_image && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              ITR File{" "}
              {existingData && existingData?.itr_file && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.itr_file)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("itr_file")}
              accept="application/pdf"
            />
            {errors.itr_file && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Utility Bill<span className={styles.requ}>*</span>
              {existingData && existingData?.utility_bill && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.utility_bill)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("utility_bill")}
            />
            {errors.utility_bill && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          {companyType === "PARTNERSHIP" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Partnership Deed<span className={styles.requ}>*</span>
                {existingData && existingData?.partnership_deed && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.partnership_deed)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("partnership_deed")}
              />
              {errors.partnership_deed && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}

          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Balance Sheet
                {existingData && existingData?.balance_sheet && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.balance_sheet)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("balance_sheet")}
                accept="application/pdf"
              />
              {errors.balance_sheet && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                MOA<span className={styles.requ}>*</span>{" "}
                {existingData && existingData?.moa && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.moa)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("moa")}
                accept="application/pdf"
              />
              {errors.moa && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                AOA<span className={styles.requ}>*</span>
                {existingData && existingData?.aoa && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.aoa)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("aoa")}
                accept="application/pdf"
              />
              {errors.aoa && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Certificate Of Incorporation
                <span className={styles.requ}>*</span>
                {existingData && existingData?.certificate_of_incorporation && (
                  <button
                    type="button"
                    onClick={() =>
                      handleEditRow(existingData.certificate_of_incorporation)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("certificate_of_incorporation")}
                accept="application/pdf"
              />
              {errors.certificate_of_incorporation && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
        </div>

        <div className={styles.buttonasc} style={{ marginTop: "15px" }}>
          <button type="submit" className={styles.Subbtn}>
            Update
          </button>
        </div>
      </form>
      {dataFromChe?.ifsc_code && ifscD && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={ifscD}
          onClick={addifsc}
        >
          <IFSCDetails addHandle={addifsc} defaultValue={rowToEdit || []} />
        </Backdrop>
      )}
    </div>
  );
};

export default DealerForm;
