import React, { useContext, useState } from "react";
import classes from "./Language.module.css";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import ChooseType from "./ChooseType";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import BorrwOtp from "./BorrwOtp";
import { Backdrop } from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
const Step1 = ({ setActiveStep, handleNext, setUserD }) => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { ver } = useContext(AuthContext);
  const [type, setType] = useState(1);
  const [add, setAdd] = useState(false);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const onSubmit = async (data) => {
    let payload;
    payload = {
      name: data.name,
      mobile: data.mobile,
      email: data.email,
    };
    // console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: `${ver}admin-borrower-registration`,
    // url: `${ver}borrower-registration`,
    method: "post",
    // invalidateKey: "langList",
    successCallback: () => {
      // reset();
      addHandle();
    },
  });

  return (
    <div className={classes.tablco}>
      <p className={classes.applheader}>BORROWER REGISTRATION</p>
      <form form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.totalcheckconnn}>
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Name<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("name", {
                required: true,
                validate: (value) =>
                  /^[A-Za-z\s]+$/.test(value) ||
                  "Only letters and spaces are allowed.",
              })}
              type="text"
              className={classes.inputstst}
            />

            {errors.name && (
              <span className={classes.error}>
                {errors.name.message || "This Field is Missing."}
              </span>
            )}
          </div>

          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Mobile Number<span className={classes.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Mobile Number"
              // {...register("mobile", { required: true })}
              {...register("mobile", {
                required: "This Field is Missing.",
                pattern: {
                  value: /^[0-9]{10}$/, // This ensures only 10 digits
                  message: "Please enter a valid 10 digit mobile number.",
                },
              })}
              type="number"
              className={classes.inputstst}
            />
            {/* 
          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )} */}
            {errors.mobile && (
              <span className={classes.error}>
                {errors.mobile.message || "This Field is Missing."}
              </span>
            )}
          </div>
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Email<span className={classes.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Mobile Number"
              {...register("email", { required: true })}
              type="email"
              className={classes.inputstst}
              onChange={(e) => {
                const lowercaseValue = e.target.value.toLowerCase();
                e.target.value = lowercaseValue;
              }}
            />

            {errors.name && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        </div>
        <div
          className={classes.buttonasc}
          style={{ marginTop: "10px", textAlign: "left" }}
        >
          {/* {!defaultValue && ( */}
          <button
            type="submit"
            className={classes.Subbtn}
            style={{ width: "90px" }}
          >
            Verify
          </button>
          {/* )} */}
        </div>
      </form>
      {/* <hr
        style={{
          border: "0.5px solid #ddd",
          margin: "20px 0",
          marginTop: "20px",
        }}
      /> */}
      {/* <ChooseType /> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <BorrwOtp
          addHandle={addHandle}
          handleNext={handleNext}
          setUserD={setUserD}
        />
      </Backdrop>
    </div>
  );
};

export default Step1;
