import { Pagination, Stack } from "@mui/material";
import React from "react";
import classes from "./PaginationComponent.module.css";

export default function PaginationComponent({
  pageChangeHandler,
  pageState,
  totalPageNo,
}) {
  return (
    <>
      <Stack>
        <Pagination
          count={totalPageNo}
          variant="outlined"
          shape="rounded"
          showFirstButton
          showLastButton
          page={pageState}
          onChange={pageChangeHandler}
          className={`${classes.pagination}`}
          classes={{
            ul: classes.paginationUl,
          }}
          sx={{
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#12468a",
              backgroundImage: "linear-gradient(135deg,  #45cb85,#12468a)",

              color: "#fff !important",
            },
            "& .MuiPagination-ul li:first-of-type .MuiPaginationItem-root": {
              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
            },
            "& .MuiPagination-ul li:last-child .MuiPaginationItem-root": {
              borderTopRightRadius: "6px",
              borderBottomRightRadius: "6px",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "14px",
            },
            "& .MuiPaginationItem-ellipsis": {
              height: "26px",
              margin: "0",
              borderRadius: "unset",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            },
            "& .MuiPaginationItem-root.Mui-disabled": {
              opacity: "unset",
            },
          }}
        />
      </Stack>
    </>
  );
}
