import React, { useState } from "react";
import classes from "../Borrower/Language.module.css";
import Breadcum from "../../Common/Breadcum";

import { useLocation } from "react-router-dom";
import ViewDet from "./ViewDet";

const OEMDetails = () => {
  const location = useLocation();
  const { val } = location.state || {};
  console.log("val", val);

  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="OEM Details" />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            <ViewDet defaultValue={val} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OEMDetails;
