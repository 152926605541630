import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaSort } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import {
  MdDelete,
  MdOutlineAddCircleOutline,
  MdPlaylistAddCheckCircle,
} from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import { useNavigate } from "react-router-dom";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [char, setChar] = useState(false);
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };
  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  // const handleEditRow = (rowIndex) => {
  //   setRowToEdit(rowIndex);
  //   setAdd((val) => !val);
  // };
  const navigate = useNavigate();
  // const handleEditRow = (val) => {
  //   navigate("/b2cAdmin/add-product", {
  //     state: { val },
  //   });
  // };
  const handleEditRow = (val) => {
    navigate("/add-product", {
      state: { val },
    });
  };
  // console.log("rowToEdit", rowToEdit);
  console.log("rowData", rowData);
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#12468a",
                backgroundImage:
                  "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>#</th>
                <th>Borrower ID</th>
                <th>Created At</th>
                <th>Name</th>

                <th style={{ minWidth: "70px" }}>Mobile</th>
                <th style={{ minWidth: "55px" }}>Status</th>
                <th style={{ minWidth: "80px" }}>Applicant Type</th>
                <th style={{ maxWidth: "250px" }}>API Flag</th>
                <th style={{ minWidth: "120px" }}>Request</th>
                <th style={{ minWidth: "120px" }}>Response</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.borrower_id}</td>
                  <td>{row.created_at}</td>
                  <td>{row.name}</td>
                  <td>{row.mobile}</td>
                  <td>{row.status}</td>
                  <td>{row.applicant_type}</td>
                  <td>{row.api_flag}</td>
                  <td>
                    {row.request
                      ? Object.entries(row.request).map(([key, value]) => (
                          <div key={key}>
                            {key}:{" "}
                            {typeof value === "object"
                              ? JSON.stringify(value)
                              : value}
                          </div>
                        ))
                      : ""}
                  </td>
                  <td>
                    {row.response
                      ? Object.entries(row.response).map(([key, value]) => (
                          <div key={key}>
                            {key}:{" "}
                            {typeof value === "object"
                              ? JSON.stringify(value)
                              : value}
                          </div>
                        ))
                      : ""}
                  </td>
                  {/* <td>
                    <span className={styles.somegapjb}>
                       {row?.request?.applicant_type}
                    </span>

                    <br />
                    {row?.request?.aadhar}
                  </td> */}
                  {/* <td>
                    <span className={styles.somegapjb}>
                      {row?.response?.message}{" "}
                    </span>
                    <br />
                    {row?.response?.msg}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        {/* <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
        {char && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={char}
            onClick={addCharHandle}
          >
            <AddCharges
              addHandle={addCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )} */}
        {/* {viewChar && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={viewChar}
            onClick={ViewCharHandle}
          >
            <ViewCharges
              addHandle={ViewCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )} */}
      </div>
    </div>
  );
};

export default Table;
