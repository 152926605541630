import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "./Search.module.css";
import { useToast } from "../../../context/ToastProvder";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
const SearchOEM = ({
  onSearch,
  filterD,
  setProduct,
  setMonth,
  setStatus,
  setStep,
}) => {
  const { register, handleSubmit, reset, watch, errors, control, setValue } =
    useForm();
  // const onSubmit = (data) => {
  //   console.log("searchForm submitted:", data);
  //   onSearch(data);
  // };<SearchComp
  //   onSearch={searchDataHandler}
  //   filterD={filterD}
  //   setStep
  //   setProduct
  //   setStatus
  //   setMonth
  // />

  const { showToast } = useToast();
  const onSubmit = (data) => {
    const { start_date, end_date } = data;

    // Check if end date is selected without a start date
    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }
    if (data.pan_no && data.pan_no.length !== 10) {
      showToast("Invalid PAN Number.");
      return;
    }
    if (data.aadhar_no && data.aadhar_no.length !== 12) {
      showToast("Invalid Aadhar Number.");
      return;
    }
    console.log("searchForm submitted:", data);
    onSearch(data);
  };

  const resetHandler = () => {
    reset();
    onSearch(null);
    setProduct(null);
    setMonth(null);
    setStatus(null);
    setStep(null);
  };
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Start Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("start_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              End Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("end_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Borrower Name
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("borrower_name")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Borrower ID
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("borrower_id")}
            />
          </div>
        </div>
        {/* <div
          className={styles.fourinputasc}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile No
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("mobile_no")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Date of Birth
            </label>
            <input
              type="date"
              className={styles.inpttsc}
              {...register("dob")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN No
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("pan_no")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Aadhar No
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("aadhar_no")}
            />
          </div>
        </div> */}
        <div className={styles.oneinputads} style={{ marginTop: "10px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Application Date
            </label>
            <input
              type="date"
              className={styles.inpttsc}
              {...register("application_date")}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchOEM;
