import React, { useContext } from "react";
import styles from "./Modal.module.css";
import close from "./../../../icons/close.png";
import { AuthContext } from "../../../context/AuthContext";

const ViewImg = ({ zoomImgUrl, addHandle }) => {
  const { baseDoc } = useContext(AuthContext);
  const fullFileUrl = `${baseDoc}${zoomImgUrl}`;
  const fileExtension = fullFileUrl.split(".").pop().toLowerCase();

  return (
    <form
      style={{ minWidth: "400px", background: "#fff" }}
      className={styles.modalcontainerr}
      onClick={(event) => event.stopPropagation()}
    >
      {/* Close Button */}
      <div style={{ textAlign: "right" }}>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            right: "5px",
            cursor: "pointer",
          }}
          alt="Close"
        />
      </div>

      {/* Conditional Rendering for Image or PDF */}
      <div
        className={styles.modalbody}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {fileExtension === "pdf" ? (
          <iframe
            src={fullFileUrl}
            title="PDF Viewer"
            style={{
              width: "600px",
              height: "500px",
              border: "none",
            }}
          />
        ) : (
          <img
            src={fullFileUrl}
            alt="Document"
            style={{ width: "600px", maxHeight: "500px" }}
          />
        )}
      </div>

      {/* Footer */}
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            {/* Add buttons or actions here if needed */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ViewImg;
