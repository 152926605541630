import React, { useState } from "react";
import classes from "../Borrower/Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import Table from "./Table";
import PaginationComponent from "../../Common/PaginationComponent";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import SearchComp from "./SearchComp";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import Loader from "../../Common/Loader";

const LenderServiceProvider = () => {
  const [page, setPage] = useState(1);
  const [perm, setPerm] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [add, setAdd] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [searchData, setSearchData] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const ShowSearchHandler = (event, value) => {
    setShowSearch((val) => !val);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const fetchData = async ({ page, numberOfRecords, searchData }) => {
    const payload = { page, number_of_records: numberOfRecords };

    if (searchData) {
      payload.name = searchData.name || "";
      payload.mobile = searchData.mobile || "";
      payload.alternative_number = searchData.alternative_number || "";
      payload.email = searchData.email || "";
      payload.pan = searchData.pan || "";
      payload.lsp_id = searchData.lsp_id || "";
      payload.date_of_incorporation = searchData.date_of_incorporation || "";
    }

    const response = await api.get("admin/loan-service-provider-list", {
      params: payload,
    });
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["langList", page, numberOfRecords, searchData],
    queryFn: () => fetchData({ page, numberOfRecords, searchData }),
    onSuccess: (data) => {
      setTotalPage(data?.data?.last_page);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("dashboardList", dashboardList);
  const handleRecordsChange = (event) => {
    setNumberOfRecords(event.target.value);
  };
  const searchDataHandler = (data) => {
    setSearchData(data);
    setPage(1);
  };
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/48");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });

  const navigate = useNavigate();

  const addHandle = () => {
    navigate("/add-loan-service-provider");
  };
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      {perm?.includes("read") && (
        <div className={classes.mainBodyContainer}>
          <Breadcum pageName="Loan Service Provider" />
          <div className={classes["main-body"]} style={{ padding: "15px" }}>
            <div className={classes.tablco}>
              {showSearch && <SearchComp onSearch={searchDataHandler} />}
              <div className={classes.inputsearchcon}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <select
                    onChange={handleRecordsChange}
                    value={numberOfRecords}
                    className={classes.selectfotnu}
                  >
                    {["10", "20", "50", "100"].map((value) => (
                      <option
                        key={value}
                        style={{ fontSize: "11px" }}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                  {perm?.includes("create") && (
                    <button
                      type="button"
                      className={classes.addbtn}
                      onClick={addHandle}
                    >
                      <IoMdAdd
                        size={11}
                        style={{
                          paddingRight: "5px",
                          position: "relative",
                          top: "1px",
                        }}
                      />
                      Add
                    </button>
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    className={classes.gobtn}
                    onClick={ShowSearchHandler}
                  >
                    <FaSearch
                      className={classes.gobtnicon}
                      size={12}
                      style={{ borderRadius: "5px", padding: "5px" }}
                    />
                  </button>
                </div>
              </div>
              <div className={classes.tableContai}>
                {isLoading || isFetching ? (
                  <Loader />
                ) : (
                  <Table
                    rowData={dashboardList?.data?.data || []}
                    page={page}
                    numberOfRecords={numberOfRecords}
                    perm={perm}
                  />
                )}
              </div>
              <div className={classes.paginCont}>
                <p className={classes.pagitext}>
                  {" "}
                  Showing {dashboardList?.data?.from} to{" "}
                  {dashboardList?.data?.to} of {dashboardList?.data?.total}{" "}
                  entries.
                </p>
                <PaginationComponent
                  pageChangeHandler={handleChange}
                  pageState={page}
                  totalPageNo={totalPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <AddModal addHandle={addHandle} />
      </Backdrop>
    </div>
  );
};

export default LenderServiceProvider;
