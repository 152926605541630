import React from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useToast } from "../../../context/ToastProvder";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
import ListWithSearc from "../../Common/ListWithSearch/ListWithSearc";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch, control } = useForm();
  // const onSubmit = (data) => {
  //   console.log("searchForm submitted:", data);
  //   onSearch(data);
  // };
  // const [
  const [{ data: depatmentList }, { data: BorrowId }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () => api.get("admin/lender-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["borrowerscreener"],
        queryFn: () =>
          api.get("admin/get-borrower-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });

  const { showToast } = useToast();
  const onSubmit = (data) => {
    const { start_date, end_date } = data;

    // Check if end date is selected without a start date
    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }

    if (data.pan && data.pan.length !== 10) {
      showToast("Invalid PAN Number.");
      return;
    }
    if (data.aadhar && data.aadhar.length !== 12) {
      showToast("Invalid Aadhar Number.");
      return;
    }
    console.log("searchForm submitted:", data);
    onSearch(data);
  };

  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.twoinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Start Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("start_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              End Date
            </label>

            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("end_date")}
            />
          </div>
        </div>

        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
