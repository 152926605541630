import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
import { useToast } from "../../../context/ToastProvder";
import axios from "axios";
const AddModal = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },

    setValue,
  } = useForm();
  const { showToast } = useToast();
  const [err, setErr] = useState({});
  useEffect(() => {
    if (defaultValue) {
      setValue("name", defaultValue.name);
      setValue("mobile", defaultValue.mobile);
      setValue("email", defaultValue.email);
      setValue("department", defaultValue?.department_id);
      setValue("user_type", defaultValue.user_type_id);
    }
  }, [defaultValue, setValue]);
  const [{ data: dealerList }] = useQueries({
    queries: [
      {
        queryKey: ["dealers"],
        queryFn: () => api.get("dealer-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("dealer_id", data.dealer_id);
    formData.append("name", data.name);
    formData.append("alternative_mobile", data.alternative_mobile);
    formData.append("aadhar", data.aadhar);
    formData.append("pan", data.pan);

    const fileFields = ["bank_statement", "itr_file", "photo"];

    const MAX_FILE_SIZE = 3072 * 1024; // 3072 KB

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 3MB: ${fieldNames}.`,
        "error"
      );
      return;
    }

    try {
      const apiEndpoint = defaultValue
        ? `https://insta.finayo.tech/api/store-dealer-authority`
        : `https://insta.finayo.tech/api//update-dealer-authority/${defaultValue.id}`;
      const method = defaultValue ? "put" : "post";
      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const { status, message } = response.data;
      showToast(message, status);
      addHandle();
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;

      setErr(message);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "520px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Edit Dealer Authority" : "Add Dealer Authority"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Dealer
            </lebel>
            <select
              {...register("dealer_id", { required: true })}
              style={{ width: "225px" }}
              className={styles.mdselecttt}
            >
              <option value="">--Select Dealer--</option>
              {dealerList?.data?.map((val) => (
                <option key={val.id} value={val.id}>
                  {val.name}
                </option>
              ))}
            </select>
            {errors.dealer_id && (
              <span className={styles.error}>This Field is Required.</span>
            )}
            <span className={styles.error}>{err.name ? err.name[0] : ""}</span>
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Name
            </lebel>
            <input
              {...register("dealer_id", { required: true })}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.name && (
              <span className={styles.error}>This Field is Required.</span>
            )}
            <span className={styles.error}>{err.name ? err.name[0] : ""}</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Mobile
            </lebel>
            <input
              {...register("mobile", {
                required: true,
                minLength: {
                  value: 10,
                  message: "10 digits Required",
                },
              })}
              type="number"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.mobile && (
              <span className={styles.error}>
                {errors.mobile.message || "This Field is Missing"}
              </span>
            )}
            <span className={styles.error}>
              {err.mobile ? err.mobile[0] : ""}
            </span>
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Alternative Mobile
            </lebel>
            <input
              {...register("alternative_mobile", {
                required: true,
                minLength: {
                  value: 10,
                  message: "10 digits Required",
                },
              })}
              type="number"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.alternative_mobile && (
              <span className={styles.error}>
                {err.email ? err.email[0] : "This Field is Missing."}
              </span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Aadhar No.
            </lebel>
            <input
              {...register("aadhar", {
                required: true,
                minLength: {
                  value: 12,
                  message: "Invalid Aadharr No.",
                },
              })}
              type="number"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.aadhar && (
              <span className={styles.error}>{"This Field is Misiing"}</span>
            )}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              PAN
            </lebel>
            <input
              {...register("pan", {
                required: true,
                // minLength: {
                //   value: 16,
                //   message: "Too Short",
                // },
              })}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.pan && (
              <span className={styles.error}>{"This Field is Misiing"}</span>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Bank Statement
            </lebel>
            <input
              {...register("bank_statement", {
                required: true,
              })}
              type="file"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />

            {errors.bank_statement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              ITR filed
            </lebel>
            <input
              {...register("itr_file", {
                required: true,
              })}
              type="file"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />

            {errors.itr_file && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Image
            </lebel>
            <input
              type="file"
              accept="application/pdf"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />

            {errors.photo && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
