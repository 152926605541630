// // import React from "react";
// // import classes from "./Sidenav.module.css";
// // import { RxDot } from "react-icons/rx";
// // const Submenu = ({ submenu, key }) => {
// //   return (
// //     <div className={classes.subme} style={{ fontSize: "11px" }}>
// //       <RxDot /> {submenu?.sbmenulebel}
// //     </div>
// //   );
// // };

// // export default Submenu;

// import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
// import classes from "./Sidenav.module.css";
// import { RxDot } from "react-icons/rx";

// const Submenu = ({ submenu, key }) => {
//   const [isActive, setIsActive] = useState(false);

//   const handleClick = () => {
//     setIsActive((prev) => !prev);
//   };
//   return (
//     <li key={submenu.id} className={classes.subme} style={{ fontSize: "11px" }}>
//       <NavLink
//         style={{
//           color: isActive ? "#fff" : "#cacddc",
//           textDecoration: "none",
//         }}
//         onClick={handleClick}
//         to={submenu.path}
//         // activeClassName={classes.active}
//       >
//         <RxDot /> {submenu.name}
//       </NavLink>
//     </li>
//   );
// };

// export default Submenu;
import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./Sidenav.module.css";
import { RxDot } from "react-icons/rx";
import { useAuth } from "../../../context/AuthContext";

const Submenu = ({ submenu, isActive, onClick }) => {
  return (
    <li key={submenu.id} className={classes.subme} style={{ fontSize: "11px" }}>
      <NavLink
        style={{
          color: isActive ? "#fff" : "#e2e3ea",
          textDecoration: "none",
          textShadow: isActive ? "rgb(0 0 0 / 80%) 0px 5px 15px" : "none",
        }}
        onClick={onClick}
        to={submenu.path}
        activeClassName={classes.active}
      >
        <RxDot /> {submenu.name}
      </NavLink>
    </li>
  );
};
export default Submenu;
