import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { useNavigate } from "react-router-dom";
import AdditionalSeven from "./AdditionalSeven";
import Thankyou from "../../../icons/Thnaks.png";
import BorroDoc from "./BorroDoc";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { useToast } from "../../../context/ToastProvder";
import { AuthContext } from "../../../context/AuthContext";
import { useForm } from "react-hook-form";
const SevenEight = ({ setActiveStep }) => {
  const [intitialStep, setInitialStep] = useState(false);
  const { borrId, dealerId } = useContext(LoanContext);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const { token, baseURL, baseDoc } = useContext(AuthContext);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [{ data: relationList }] = useQueries({
    queries: [
      {
        queryKey: ["rela", borrId, dealerId],
        queryFn: async () => {
          const payload = { borrower_id: borrId, lender_id: dealerId };
          const res = await api.post("admin/get-loan-terms", payload);
          return res.data;
        },
        enabled: !!borrId && !!dealerId,
        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          //   console.log("Loan Status:", response.data);

          // const { product_id } = response?.data;
          const { step } = response?.data?.step_details;

          if (step === 10) {
            setInitialStep(true);
          }
        })
        .catch((error) => {
          //    console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  const handleConcent = async (consent) => {
    try {
      const payload = {
        borrower_id: borrId,
        lender_consent: consent,
      };

      const response = await api.post("admin/lender-consent", payload);
      const { status, message } = response.data;
      if (response.status === 200 || response.data.status === "success") {
        //  console.log("API call successful:", response.data);
        setInitialStep(true);
        showToast(message, status);
        if (consent === "NO") {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      const { message, data: errorDetails } = error.response.data;
      showToast(message, "error");
      // console.error(
      //   "Error while submitting data:",
      //   error.response?.data || error.message
      // );
    }
  };
  const onSubmit = async (data) => {
    // if (data.name_1 === data.name_2) {
    //   showToast(
    //     "Reference 1 and Reference 2 details cannot be the same.",
    //     "error"
    //   );
    //   return;
    // }
    // if (data.mobile_1 === data.mobile_2) {
    //   showToast(
    //     "Reference 1 and Reference 2 details cannot be the same.",
    //     "error"
    //   );
    //   return;
    // }
    // const formData = new FormData();
    // formData.append("borrower_id", borrId);
    // formData.append("name_1", data.name_1);
    // formData.append("name_2", data.name_2);
    // formData.append("mobile_1", data.mobile_1);
    // formData.append("mobile_2", data.mobile_2);
    // formData.append("relation_2", data.relation_2);
    // formData.append("relation_1", data.relation_1);
    // formData.append("address_1", data.address_1);
    // formData.append("address_2", data.address_2);
    // try {
    //   const apiEndpoint = `${baseURL}admin/store-reference-details`;
    //   const response = await axios.post(apiEndpoint, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    //   const { status, message } = response.data;
    //   showToast(message, status);
    //   //  console.log("Form submitted successfully:", response.data);
    //   setInitialStep(true);
    // } catch (error) {
    //   //  console.error("Error submitting form:", error);
    //   const { message, errors } = error.response.data;
    //   //  console.log("message", message);
    //   showToast(message, "error");
    // }
  };
  return (
    <div
      className={classes.tablco}
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    >
      {/* <div>
        {!intitialStep && (
          <form onSubmit={handleSubmit(onSubmit)} className={classes.tablco}>
            <p className={classes.applheader}>Borrower Account Details</p>
            <div className={classes.totalcheckconnn}>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Account Holder Name<span className={classes.requ}>*</span>
                </label>
                <input
                 
                  {...register("name_1", {
                    required: true,
                    validate: (value) =>
                      /^[A-Za-z\s]+$/.test(value) ||
                      "Only letters and spaces are allowed.",
                  })}
                  type="text"
                  className={classes.inputstst}
                />

                {errors.name_1 && (
                  <span className={classes.error}>
                    {errors.name_1.message || "This Field is Missing."}
                  </span>
                )}
              </div>

              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Account Number<span className={classes.requ}>*</span>
                </label>
                <input
                  {...register("address_1", { required: true })}
                  type="text"
                  className={classes.inputstst}
                />

                {errors.address_1 && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  IFSC Code<span className={classes.requ}>*</span>
                </label>
                <input
                  {...register("address_1", { required: true })}
                  type="text"
                  className={classes.inputstst}
                />

                {errors.address_1 && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Bank Name<span className={classes.requ}>*</span>
                </label>
                <input
                  {...register("address_1", { required: true })}
                  type="text"
                  className={classes.inputstst}
                />

                {errors.address_1 && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Branch Code
                </label>
                <input
                  {...register("address_1")}
                  type="text"
                  className={classes.inputstst}
                />
              </div>
            </div>
            <hr
              style={{
                border: "0.5px solid #ddd",
                margin: "15px 0",
                marginTop: "20px",
              }}
            />

            <div
              className={classes.buttonasc}
              style={{ marginTop: "10px", textAlign: "left" }}
            >
        
              <button
                type="submit"
                className={classes.Subbtn}
               
                style={{ width: "80px" }}
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </div> */}
      {!intitialStep && (
        <div
          style={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: "15px",
            width: "60%",
            margin: "0 auto",
            textAlign: "left",
            borderRadius: "7px",
          }}
        >
          {/* <img src={Thankyou} style={{ width: "300px" }} /> */}
          <p className={classes.thnktt}>You Have Selected A Lender</p>
          <p className={classes.btmthnktt}>{relationList?.data?.description}</p>
          <div>
            <p className={classes.bnkNamet}>
              Lender's Name: {relationList?.data?.lender}
            </p>
            <p className={classes.bnkNamett}>Process By Finayo</p>
            <ul className={classes.bnkNamett}>
              {relationList?.data?.process_by_finayo?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div style={{ marginTop: "15px" }}>
            <button
              type="button"
              onClick={() => handleConcent("NO")}
              className={classes.Subbtn}
              style={{
                width: "80px",
                background: "#c6131b",
                marginRight: "15px",
              }}
            >
              Reject
            </button>
            <button
              type="button"
              className={classes.Subbtn}
              onClick={() => handleConcent("YES")}
              style={{ width: "80px" }}
            >
              Accept
            </button>
          </div>
        </div>
      )}
      {intitialStep && <BorroDoc />}
    </div>
  );
};

export default SevenEight;
