import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Language.module.css";
import VehicDoc from "./VehicDoc";
import StepFifthee from "./StepFifthee";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
import { useToast } from "../../../context/ToastProvder";
const StepFoutte = () => {
  const { borrId, userId, userD } = useContext(LoanContext);
  const { showToast } = useToast();
  const [intitialStep, setInitialStep] = useState(false);
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          // console.log("Loan Status:", response.data);

          const { product_id, step_details } = response?.data;
          const { step } = step_details;

          // setProductId(product_id);

          if (step === 27) {
            setInitialStep(true);
          }
          if (step === 28) {
            showToast("Your Application is Submitted", "success");
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          //   console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  return (
    <div className={classes.tablco}>
      {!intitialStep && <VehicDoc setInitialStep={setInitialStep} />}
      {intitialStep && <StepFifthee />}
    </div>
  );
};

export default StepFoutte;
