import React, { useEffect } from "react";
import styles from "../CreateDealer/BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useNavigate } from "react-router-dom";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
const AddGenerateR = ({ defaultValue }) => {
  console.log("defaultValue in", defaultValue);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: new Date().toISOString().split("T")[0], // Setting today's date as default
    },
  });
  const navigate = useNavigate();
  const [
    { data: lenderList },
    { data: VehicleList },
    { data: ProductList },
    { data: oemList },
    { data: paymentMethodList },
    { data: borrowerList },
  ] = useQueries({
    queries: [
      {
        queryKey: ["lenderScre"],
        queryFn: () => api.get("admin/lender-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["vehicleType"],
        queryFn: () => api.get("vehicle-type-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["prodtype"],
        queryFn: () => api.get("product-type-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["dealers"],
        queryFn: () =>
          api.get("admin/get-oem-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["methodlist"],
        queryFn: () =>
          api.get("admin/get-payment-method-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("admin/get-all-borrower-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });

  useEffect(() => {
    if (defaultValue) {
      setValue("lender_id", defaultValue?.lender_id);
      setValue("product_name", defaultValue?.product_name);
      setValue("battery_type", defaultValue?.battery_type);
      setValue("ex_showroom_price", defaultValue?.ex_showroom_price);
      setValue("loan_to_value_ratio", defaultValue?.loan_to_value_ratio);
      setValue("manufacture_name", defaultValue?.manufacture_name);
      setValue("min_loan_amount", defaultValue?.min_loan_amount);
      setValue("max_loan_amount", defaultValue?.max_loan_amount);
      setValue("min_loan_tenure", defaultValue?.min_loan_tenure);
      setValue("max_loan_tenure", defaultValue?.max_loan_tenure);
      setValue("product_type", defaultValue?.product_type);
      setValue("interest_rate", defaultValue?.interest_rate);
      setValue("processing_fee", defaultValue?.processing_fee);
      setValue("subvention_amount", defaultValue?.subvention_amount);
      setValue("fldg_amount", defaultValue?.fldg_amount);
      setValue("iot_amount", defaultValue?.iot_amount);
      setValue("insurance_1st_year", defaultValue?.insurance_1st_year);
      setValue("insurance_2nd_year", defaultValue?.insurance_2nd_year);
    }
  }, [defaultValue, setValue, oemList, lenderList, ProductList]);

  const onSubmit = async (data) => {
    let payload;
    payload = {
      lender_id: data.lender_id || "",
      product_name: data.product_name || "",
      battery_type: data.battery_type || "",
      ex_showroom_price: data.ex_showroom_price || "",
      loan_to_value_ratio: data.loan_to_value_ratio || "",
      // manufacture_name: data.manufacture_name || "",
      min_loan_amount: data.min_loan_amount || "",
      max_loan_amount: data.max_loan_amount || "",
      min_loan_tenure: data.min_loan_tenure || "",
      max_loan_tenure: data.max_loan_tenure || "",
      product_type: data.product_type || "",
      interest_rate: data.interest_rate || "",
      processing_fee: data.processing_fee || "",
      subvention_amount: data.subvention_amount || "",
      fldg_amount: data.fldg_amount || "",
      iot_amount: data.iot_amount || "",
      insurance_1st_year: data.insurance_1st_year || "",
      insurance_2nd_year: data.insurance_2nd_year || "",
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `admin/update-oem-product-details/${defaultValue.id}`
      : "admin/store-oem-product",
    method: "post",
    invalidateKey: "btryTypeList",
    successCallback: () => {
      reset();
      navigate("/oem-product-list");
    },
  });
  return (
    <div style={{ marginBottom: "15px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          <div className={styles.totalcheckconnn}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Reciept Number<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("battery_type", {
                  required: true,
                })}
              />

              {errors.product_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Lender<span className={styles.requ}>*</span>
              </label>

              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ListWithSearch
                    options={lenderList?.data || []}
                    getOptionLabel={(val) => val?.name || ""}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.id}>{`${option?.name}`}</li>
                    )}
                    className={styles.listSrch}
                    onChange={(event, selectedValue) => {
                      onChange(selectedValue ? selectedValue?.id : null);
                    }}
                    value={
                      lenderList?.data?.find(
                        (employee) => employee?.id === value
                      ) || null
                    }
                  />
                )}
                name={`lender_id`}
              />

              {errors.lender_id && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Borrower<span className={styles.requ}>*</span>
              </label>

              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ListWithSearch
                    options={borrowerList?.data || []}
                    getOptionLabel={(val) => val.name || ""}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={option.id}
                      >{`${option.name} (${option.borrower_id})`}</li>
                    )}
                    className={styles.listSrch}
                    onChange={(event, selectedValue) => {
                      onChange(selectedValue ? selectedValue?.id : null);
                    }}
                    value={
                      borrowerList?.data?.find(
                        (employee) => employee.id === value
                      ) || null
                    }
                  />
                )}
                name={`borrower_id`}
              />

              {errors.lender_id && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Loan Account Number<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("battery_type", {
                  required: true,
                })}
              />

              {errors.product_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                EMI Amount<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.00"
                className={styles.inpttsc}
                {...register("battery_type", {
                  required: true,
                })}
              />

              {errors.product_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date<span className={styles.requ}>*</span>
              </label>
              <input
                type="date"
                className={styles.inpttsc}
                // {...register("date", {
                //   required: true,
                // })}
                defaultValue={new Date().toISOString().split("T")[0]}
              />

              {errors.product_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Due Date<span className={styles.requ}>*</span>
              </label>
              <input
                type="date"
                className={styles.inpttsc}
                {...register("date", {
                  required: true,
                })}
                defaultValue={new Date().toISOString().split("T")[0]}
              />

              {errors.product_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Payment Recieved Date<span className={styles.requ}>*</span>
              </label>
              <input
                type="date"
                className={styles.inpttsc}
                {...register("date", {
                  required: true,
                })}
                defaultValue={new Date().toISOString().split("T")[0]}
              />

              {errors.product_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Payment Mode<span className={styles.requ}>*</span>
              </label>

              <select
                className={styles.mdselecttt}
                {...register("payment_method", {
                  required: true,
                })}
              >
                <option value="">--Select Payment Method--</option>
                {paymentMethodList?.data?.map((row, i) => (
                  <option key={i} value={row.id}>
                    {row.payment_method}
                  </option>
                ))}
              </select>
              {errors.battery_type && (
                <span className={styles.error}>"This Field is Missing."</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Transaction ID<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("battery_type", {
                  required: true,
                })}
              />

              {errors.product_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Number of EMI<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("battery_type", {
                  required: true,
                })}
              />

              {errors.product_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Total Amount<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.01"
                className={styles.inpttsc}
                {...register("battery_type", {
                  required: true,
                })}
              />

              {errors.product_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Remarks<span className={styles.requ}>*</span>
              </label>
              <textarea
                style={{ height: "35px" }}
                type="text"
                className={styles.inpttsc}
                {...register("battery_type", {
                  required: true,
                })}
              />

              {errors.product_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>

          <div className={styles.buttonasc}>
            <button type="submit" className={styles.Subbtn}>
              {defaultValue ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddGenerateR;
