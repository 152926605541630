import React, { Suspense, useContext, useEffect, useState } from "react";
import { BsPersonVcardFill, BsFillPersonFill } from "react-icons/bs";
import { MdNotifications } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";
import { FaComment, FaKey, FaLock, FaUserCircle } from "react-icons/fa";
import classes from "./Header.module.css";
import { IoMdQrScanner } from "react-icons/io";
import { MdOutlineNightlight } from "react-icons/md";
import profl from "./../../../icons/profl.jpg";
// import { useDispatch, useSelector } from "react-redux";
// import { authActions } from "../../store/StoreSlices/authSlice";
// import NoImg from "../../icons/NoImg";
import { NavLink } from "react-router-dom";
import { IoNotificationsOutline } from "react-icons/io5";
import { AuthContext } from "../../../context/AuthContext";
import { RiLogoutBoxRLine } from "react-icons/ri";
// import NotificationComponent from "./NotificationComponent";

const HeaderBar = ({ onHide, hide }) => {
  const { token, setToken, setUser, baseURL, loginData } =
    useContext(AuthContext);
  const [userDetails, setUserDetails] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `${baseURL}admin/user`,
          // "https://insta.finayo.tech/api/admin/user",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`, // Attach token in the header
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setUserDetails(data.data);
          setUser(data.data);
        } else {
          //  console.error("Failed to fetch user details", response.statusText);
        }
      } catch (error) {
        // console.error("An error occurred while fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [token]);

  const logout = async () => {
    try {
      const response = await fetch(
        "https://insta.finayo.tech/api/admin/logout",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setToken(null);
        localStorage.removeItem("ACCESS_TOKEN");
        // window.location.href = "/login";
      } else {
        //  console.error("Logout failed", response.statusText);
        localStorage.removeItem("ACCESS_TOKEN");
      }
    } catch (error) {
      // console.error("An error occurred during logout:", error);
      localStorage.removeItem("ACCESS_TOKEN");
    }
  };

  const handleMouseEnter = () => {
    setIsDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownVisible(false);
  };
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <header className={classes.navbar}>
      <div className={classes["navbar__title"]}>
        <div className={classes.menuIcon}>
          {!hide && (
            <AiOutlineMenu
              onClick={() => {
                onHide();
              }}
              size="25px"
              style={{ color: "#495057", fontWeight: "bold" }}
            />
          )}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className={classes["headerAction"]}>
          <div
            className={classes["navbar__item"]}
            onClick={() => {
              onHide();
            }}
            style={{ fontSize: "20px" }}
          >
            <IoMdQrScanner style={{ color: "#12468a" }} />
          </div>
        </div>
        <div
          style={{
            padding: "10px",
            // background: "#f3f3f9",
            maxHeight: "33px",
            marginLeft: "10px",
            display: "flex",
            cursor: "pointer",
            background:
              "linear-gradient(320deg, #1c6088, #339c86, #12468a, #12468a)",
            boxShadow: "2px 5px 10px rgba(0, 0, 0, 0.2)",
            borderRadius: " 0 0 15px 15px",
          }}
          onClick={toggleDropdown}
        >
          <img
            src={profl}
            style={{
              borderRadius: "100%",
              width: "30px",
              height: "30px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
              textAlign: "left",
            }}
          >
            <h5 style={{ margin: "0px", fontSize: "11px", color: "#fff" }}>
              {userDetails?.name || loginData?.name || "User"}
            </h5>
            <h4 style={{ margin: "0px", fontSize: "9px", color: "#fff" }}>
              {userDetails?.department || loginData?.department || "Department"}
            </h4>
          </div>
          {isDropdownVisible && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                right: "20px",
                background: "#fff",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                width: "130px",
                zIndex: 100,
                paddingTop: "10px",
              }}
            >
              <NavLink
                // to="/b2cAdmin/profile"
                to="/profile"
                className={classes.proco}
                style={{
                  display: "block",
                  padding: "10px",
                  textDecoration: "none",
                  color: "#878a99",
                  borderBottom: "1px solid #f3f3f9",
                  fontWeight: "bold",
                }}
                onClick={() => setIsDropdownVisible(false)}
              >
                <FaUserCircle
                  size={16}
                  style={{
                    paddingRight: "7px",
                    position: "relative",
                    top: "3px",
                    color: "#878a99",
                  }}
                />{" "}
                Profile
              </NavLink>
              <div
                style={{
                  display: "block",
                  padding: "10px",
                  textDecoration: "none",
                  cursor: "pointer",
                  color: " #878a99",
                  fontWeight: "bold",
                }}
                className={classes.proco}
                onClick={logout}
              >
                <RiLogoutBoxRLine
                  size={16}
                  style={{
                    paddingRight: "7px",
                    position: "relative",
                    top: "3px",
                    color: " #878a99",
                  }}
                />
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderBar;
