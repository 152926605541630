import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";

const RemarkView = ({ addHandle, defaultValue }) => {
  console.log("defaultValue in remar", defaultValue);
  return (
    <form
      style={{ width: "750px" }}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          Remarks{" "}
          {defaultValue?.status === "active"
            ? "For Activate "
            : "For Inactivate"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div className={styles.modalform}>
          <div className={styles.twoninputcont}>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                {defaultValue?.remarks}
              </label>
              {/* <textarea {...register("remarks")} className={styles.mdselct} /> */}
              {/* <select {...register("remarks")} className={styles.mdselct}>
                <option disabled>--Select Product Type--</option>
              </select> */}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RemarkView;
