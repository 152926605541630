import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";

const AddModal = ({ addHandle, defaultValue, type }) => {
  const [err, setErr] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();
  console.log({ type });
  console.log({ defaultValue });
  const onSubmit = async (data) => {
    let payload;
    payload = {
      remarks: data.remarks,
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url:
      type === "approve"
        ? `admin/approve-product-excel/${defaultValue}`
        : `admin/reject-product-excel/${defaultValue}`,
    method: defaultValue ? "put" : "put",
    invalidateKey: "bankDetailsList",
    setErr: setErr,
    successCallback: () => {
      reset();
      addHandle();
    },
  });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "750px" }}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Remarks</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div className={styles.modalform}>
          <div className={styles.twoninputcont}>
            <div className={styles.inptconnt}>
              {/* <label
                style={{
                  marginBottom: "0.5rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Remarks
              </label> */}
              <textarea {...register("remarks")} className={styles.mdselct} />
              {/* <select {...register("remarks")} className={styles.mdselct}>
                <option disabled>--Select Product Type--</option>
              </select> */}
            </div>
          </div>
        </div>
      </div>

      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
