import React, { useContext, useEffect } from "react";
import styles from "./Language.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
import ixo from "../../../icons/finayo_logo.png";
import { QueryClient, useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
import ZoomImg from "./ZoomImg";
const DealerForm = ({ doc, setDtt, dtt, borrowerId }) => {
  const { user, token, setUser, setToken, baseURL, baseDoc } =
    useContext(AuthContext);
  const [formdata, setFormdata] = useState(null);

  const [perm, setPerm] = useState([]);
  // const fetchPermissions = async () => {
  //   const response = await api.get("admin/get-sub-menu-permissions/48");
  //   return response.data;
  // };
  // const PermissionsResponse = useQuery({
  //   queryKey: ["submenuPermissions"],
  //   queryFn: fetchPermissions,
  //   onSuccess: (data) => {
  //     setPerm(data.data);
  //   },
  // });
  // const { data: permissionsData } = PermissionsResponse;
  const navigate = useNavigate();
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [bimg, setBimg] = useState(false);
  const [zoomImgUrl, setZoomImgUrl] = useState(null);
  const { showToast } = useToast();
  const imgHandle = (url) => {
    setZoomImgUrl(url);
    setBimg((val) => !val);
  };
  const aadharFront = watch("aadhar_front");
  const aadharBack = watch("aadhar_back");
  const panDoc = watch("pan_doc");
  const resAddressProof = watch("res_address_proof");
  const borrowerAvatar = watch("borrower_avtar");
  const urcCertificate = watch("urc_certificate");
  const drivingLicense = watch("driving_license");
  const coAadharFront = watch("coAadhar_front");
  const coAadharBack = watch("coAadhar_back");
  const coPanDoc = watch("coPan_doc");
  const coApplicantAvatar = watch("coapplicant_avtar");
  const coResAddressProof = watch("coResAddress_proof");
  const guarantorAadharFront = watch("guarantor_aadhar_front");
  const guarantorAadharBack = watch("guarantor_aadhar_back");
  const guarantorPanDoc = watch("guarantor_pan_doc");
  const guarantorAvatar = watch("guarantor_avtar");
  const guarantorCoResAddressProof = watch("guarantor_coresaddress_proof");
  const rtoDocument = watch("rto_document");
  const rtoTaxReceipt = watch("rto_tax_receipt");
  const mmr = watch("mmr");
  const invoice = watch("invoice");
  const insurance = watch("insurance");
  const vehicleImage = watch("vehicle_image");
  const doh = watch("doh");
  const chasisNumber = watch("chasis_number");
  const motorController = watch("motor_controller");
  const batteryImage = watch("battery_image");
  const registrationCertificate = watch("registration_certificate");
  const borrowerRentDoc = watch("borrower_rent_doc");
  const coapplicantRentDoc = watch("coapplicant_rent_doc");
  const guarantorRentDoc = watch("guarantor_rent_doc");
  const borrowerNameDeclaration = watch("borrower_name_declaration");
  const coapplicantNameDeclaration = watch("coapplicant_name_declaration");
  const guarantorNameDeclaration = watch("guarantor_name_declaration");
  const signedCheque_1 = watch("signed_cheque_1");
  const signedCheque_2 = watch("signed_cheque_2");
  const signedCheque_3 = watch("signed_cheque_3");
  const signedCheque_4 = watch("signed_cheque_4");
  const otherDoc_1 = watch("otherDoc1");
  const otherDoc_2 = watch("otherDoc2");
  const otherDoc_3 = watch("otherDoc3");
  const otherDoc_4 = watch("otherDoc4");
  const otherVeh_1 = watch("otherVeh1");
  const otherVeh_2 = watch("otherVeh2");
  const otherVeh_3 = watch("otherVeh3");
  const otherVeh_4 = watch("otherVeh4");
  const vsignedCheque_1 = watch("vsignedCheque1");
  const vsignedCheque_2 = watch("vsignedCheque2");
  //    "otherDoc_1",otherVeh_3
  //    "vsignedCheque2",
  useEffect(() => {
    const fieldsToCheck = [
      { field: vsignedCheque_1, remark: "vsignedCheque1_remark" },
      { field: vsignedCheque_2, remark: "vsignedCheque2_remark" },
      { field: otherVeh_1, remark: "otherVeh1_remark" },
      { field: otherVeh_2, remark: "otherVeh2_remark" },
      { field: otherVeh_3, remark: "otherVeh3_remark" },
      { field: otherVeh_4, remark: "otherVeh4_remark" },
      { field: otherDoc_1, remark: "otherDoc1_remark" },
      { field: otherDoc_2, remark: "otherDoc2_remark" },
      { field: otherDoc_3, remark: "otherDoc3_remark" },
      { field: otherDoc_4, remark: "otherDoc4_remark" },
      { field: signedCheque_1, remark: "signed_cheque_1_remark" },
      { field: signedCheque_2, remark: "signed_cheque_2_remark" },
      { field: signedCheque_3, remark: "signed_cheque_3_remark" },
      { field: signedCheque_4, remark: "signed_cheque_4_remark" },
      { field: aadharFront, remark: "aadhar_front_remark" },
      { field: aadharBack, remark: "aadhar_back_remark" },
      { field: panDoc, remark: "pan_doc_remark" },
      { field: resAddressProof, remark: "res_address_proof_remark" },
      { field: borrowerAvatar, remark: "borrower_avtar_remark" },
      { field: urcCertificate, remark: "urc_certificate_remark" },
      { field: drivingLicense, remark: "driving_license_remark" },
      { field: coAadharFront, remark: "coAadhar_front_remark" },
      { field: coAadharBack, remark: "coAadhar_back_remark" },
      { field: coPanDoc, remark: "coPan_doc_remark" },
      { field: coApplicantAvatar, remark: "coapplicant_avtar_remark" },
      { field: coResAddressProof, remark: "coResAddress_proof_remark" },
      {
        field: guarantorAadharFront,
        remark: "guarantor_aadhar_front_remark",
      },
      { field: guarantorAadharBack, remark: "guarantor_aadhar_back_remark" },
      { field: guarantorPanDoc, remark: "guarantor_pan_doc_remark" },
      { field: guarantorAvatar, remark: "guarantor_avtar_remark" },
      {
        field: guarantorCoResAddressProof,
        remark: "guarantor_coresaddress_proof_remark",
      },
      { field: rtoDocument, remark: "rto_document_remark" },
      { field: rtoTaxReceipt, remark: "rto_tax_receipt_remark" },
      { field: mmr, remark: "mmr_remark" },
      { field: invoice, remark: "invoice_remark" },
      { field: insurance, remark: "insurance_remark" },
      { field: vehicleImage, remark: "vehicle_image_remark" },
      { field: doh, remark: "doh_remark" },
      { field: chasisNumber, remark: "chasis_number_remark" },
      { field: motorController, remark: "motor_controller_remark" },
      { field: batteryImage, remark: "battery_image_remark" },
      { field: borrowerRentDoc, remark: "borrower_rent_doc_remark" },
      {
        field: registrationCertificate,
        remark: "registration_certificate_remark",
      },
      { field: coapplicantRentDoc, remark: "coapplicant_rent_doc_remark" },
      { field: guarantorRentDoc, remark: "guarantor_rent_doc_remark" },
      {
        field: borrowerNameDeclaration,
        remark: "borrower_name_declaration_remark",
      },
      {
        field: coapplicantNameDeclaration,
        remark: "coapplicant_name_declaration_remark",
      },
      {
        field: guarantorNameDeclaration,
        remark: "guarantor_name_declaration_remark",
      },
    ];

    fieldsToCheck.forEach(({ field, remark }) => {
      if (field && field.length > 0) {
        register(remark, { required: true });
      } else {
        unregister(remark);
        setValue(remark, "");
      }
    });
  }, [
    aadharFront,
    aadharBack,
    panDoc,
    resAddressProof,
    borrowerAvatar,
    urcCertificate,
    drivingLicense,
    coAadharFront,
    coAadharBack,
    coPanDoc,
    coApplicantAvatar,
    coResAddressProof,
    guarantorAadharFront,
    guarantorAadharBack,
    guarantorPanDoc,
    guarantorAvatar,
    guarantorCoResAddressProof,
    rtoDocument,
    rtoTaxReceipt,
    mmr,
    invoice,
    insurance,
    vehicleImage,
    vsignedCheque_1,
    vsignedCheque_2,
    signedCheque_1,
    signedCheque_2,
    signedCheque_3,
    signedCheque_4,
    doh,
    chasisNumber,
    motorController,
    batteryImage,
    registrationCertificate,
    borrowerRentDoc,
    coapplicantRentDoc,
    guarantorNameDeclaration,
    coapplicantNameDeclaration,
    borrowerNameDeclaration,
    guarantorRentDoc,
    otherDoc_1,
    otherDoc_2,
    otherDoc_3,
    otherDoc_4,
    otherVeh_1,
    otherVeh_2,
    otherVeh_3,
    otherVeh_4,
    register,
    unregister,
    setValue,
  ]);
  // "otherDoc_1", otherVeh_3;
  // const aadharFront = watch("aadhar_front") || [];
  // console.log("aadharFront.length", aadharFront.length);
  // useEffect(() => {
  //   if (aadharFront.length > 0) {
  //     register("aadhar_front_remark", { required: true });
  //   } else {
  //     unregister("aadhar_front_remark");
  //     setValue("aadhar_front_remark", "");
  //   }
  // }, [register, unregister, setValue, aadharFront]);
  const fileFields = [
    "aadhar_front",
    "aadhar_back",
    "pan_doc",
    "res_address_proof",
    "borrower_avtar",
    "urc_certificate",
    "driving_license",
    "coAadhar_front",
    "coAadhar_back",
    "coPan_doc",
    "coapplicant_avtar",
    "coResAddress_proof",
    "guarantor_aadhar_front",
    "guarantor_aadhar_back",
    "guarantor_pan_doc",
    "guarantor_avtar",
    "guarantor_coresaddress_proof",
    "rto_document",
    "rto_tax_receipt",
    "mmr",
    "invoice",
    "insurance",
    "vehicle_image",
    "doh",
    "chasis_number",
    "motor_controller",
    "battery_image",
    "registration_certificate",
    "signed_cheque_1",
    "signed_cheque_2",
    "signed_cheque_3",
    "signed_cheque_4",
    "otherDoc1",
    "otherDoc2",
    "otherDoc3",
    "otherDoc4",
    "otherVeh1",
    "otherVeh2",
    "otherVeh3",
    "otherVeh4",
    "vsignedCheque1",
    "vsignedCheque2",
  ];

  // const watchedFiles = watch(fileFields);

  // useEffect(() => {
  //   fileFields.forEach((field, index) => {
  //     const file = watchedFiles[index];
  //     const remarkField = `${field}_remark`;

  //     if (file && file.length > 0) {
  //       register(remarkField, { required: true });
  //     } else {
  //       unregister(remarkField);
  //       setValue(remarkField, "");
  //     }
  //   });
  // }, [watchedFiles, register, unregister, setValue]);

  const onSubmit = async (data) => {
    console.log("Form data to submit:", data);
    const formData = new FormData();
    formData.append("borrower_id", borrowerId);
    formData.append("aadhar_front_remark", data.aadhar_front_remark);
    formData.append("aadhar_back_remark", data.aadhar_back_remark);
    formData.append("pan_doc_remark", data.pan_doc_remark);
    formData.append("res_address_proof_remark", data.res_address_proof_remark);
    formData.append("borrower_avtar_remark", data.borrower_avtar_remark);
    formData.append("urc_certificate_remark", data.urc_certificate_remark);
    formData.append("driving_license_remark", data.driving_license_remark);
    formData.append("borrower_rent_doc_remark", data.borrower_rent_doc_remark);
    formData.append("coAadhar_front_remark", data.coAadhar_front_remark);
    formData.append("coAadhar_front_remark", data.coAadhar_front_remark);
    formData.append("coAadhar_back_remark", data.coAadhar_back_remark);
    formData.append("coPan_doc_remark", data.coPan_doc_remark);
    formData.append(
      "coapplicant_rent_doccoPan_doc_remark",
      data.coapplicant_rent_doc_remark
    );
    formData.append(
      "coResAddress_proof_remark",
      data.coResAddress_proof_remark
    );
    formData.append("coapplicant_avtar_remark", data.coapplicant_avtar_remark);
    formData.append(
      "guarantor_aadhar_front_remark",
      data.guarantor_aadhar_front_remark
    );
    formData.append(
      "guarantor_aadhar_back_remark",
      data.guarantor_aadhar_back_remark
    );
    formData.append("guarantor_pan_doc_remark", data.guarantor_pan_doc_remark);
    formData.append("guarantor_avtar_remark", data.guarantor_avtar_remark);
    formData.append(
      "guarantor_coresaddress_proof_remark",
      data.guarantor_coresaddress_proof_remark
    );
    formData.append("rto_document_remark", data.rto_document_remark);
    formData.append("rto_tax_receipt_remark", data.rto_tax_receipt_remark);
    formData.append("mmr_remark", data.mmr_remark);
    formData.append("invoice_remark", data.invoice_remark);
    formData.append("insurance_remark", data.insurance_remark);
    formData.append("vehicle_image_remark", data.vehicle_image_remark);
    formData.append("doh_remark", data.doh_remark);
    formData.append("chasis_number_remark", data.chasis_number_remark);
    formData.append("motor_controller_remark", data.motor_controller_remark);
    formData.append("battery_image_remark", data.battery_image_remark);
    formData.append(
      "coapplicant_name_declaration_remark",
      data.coapplicant_name_declaration_remark
    );
    formData.append(
      "coapplicant_name_declaration_remark",
      data.coapplicant_name_declaration_remark
    );
    formData.append(
      "borrower_name_declaration_remark",
      data.borrower_name_declaration_remark
    );
    formData.append(
      "guarantor_rent_doc_remark",
      data.guarantor_rent_doc_remark
    );
    formData.append(
      "registration_certificate_remark",
      data.registration_certificate_remark
    );

    const fileFields = [
      "aadhar_front",
      "aadhar_back",
      "pan_doc",
      "res_address_proof",
      "borrower_avtar",
      "urc_certificate",
      "driving_license",
      "borrower_rent_doc",
      "coapplicant_rent_doc",
      "coAadhar_front",
      "coAadhar_back",
      "coPan_doc",
      "coapplicant_avtar",
      "coResAddress_proof",
      "coResAddress_proof",
      "guarantor_aadhar_front",
      "guarantor_aadhar_back",
      "guarantor_rent_doc",
      "borrower_name_declaration",
      "coapplicant_name_declaration",
      "guarantor_name_declaration",
      "guarantor_pan_doc",
      "guarantor_avtar",
      "guarantor_coresaddress_proof",
      "rto_document",
      "rto_tax_receipt",
      "mmr",
      "invoice",
      "insurance",
      "vehicle_image",
      "doh",
      "chasis_number",
      "motor_controller",
      "battery_image",
      "registration_certificate",
    ];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    const signedChequeFields = [
      "signed_cheque_1",
      "signed_cheque_2",
      "signed_cheque_3",
      "signed_cheque_4",
    ];

    const signedChequeRemarks = [];

    signedChequeFields.forEach((field, index) => {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(`borrower_signed_cheque[${index}]`, file);
        }
      } else {
        formData.append(`borrower_signed_cheque[${index}]`, "");
      }

      const remarkField = `${field}_remark`;
      const remark = data[remarkField] || "";
      signedChequeRemarks.push(remark);
    });

    formData.append(
      "vehicle_signed_cheque_remark",
      JSON.stringify(signedChequeRemarks)
    );
    const otherBorDoc = [];
    const otherBorDocRemarks = [];
    const otherBorDocFields = [
      "otherDoc1",
      "otherDoc2",
      "otherDoc3",
      "otherDoc4",
    ];

    otherBorDocFields.forEach((field, index) => {
      // Handle file inputs
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(`borrower_other_docs[${index}]`, file);
        }
      } else {
        formData.append(`borrower_other_docs[${index}]`, "");
      }

      const remarkField = `${field}_remark`;
      const remark = data[remarkField] || "";
      otherBorDocRemarks.push(remark);
    });
    formData.append(
      "borrower_other_docs_remark",
      JSON.stringify(otherBorDocRemarks)
    );

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }

    const otherVehDoc = [];
    const otherVehDocRemarks = [];
    const otherVehDocFields = [
      "otherVeh1",
      "otherVeh2",
      "otherVeh3",
      "otherVeh4",
    ];

    otherVehDocFields.forEach((field, index) => {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(`vehicle_other_docs[${index}]`, file);
        }
      } else {
        formData.append(`vehicle_other_docs[${index}]`, "");
      }

      const remarkField = `${field}_remark`;
      const remark = data[remarkField] || "";
      otherVehDocRemarks.push(remark);
    });
    formData.append(
      "vehicle_other_docs_remark",
      JSON.stringify(otherVehDocRemarks)
    );

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    const VsignedChequeFields = ["vsignedCheque1", "vsignedCheque2"];
    const VsignedChequeRemarks = [];

    VsignedChequeFields.forEach((field, index) => {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(`vehicle_signed_cheque[${index}]`, file);
        }
      } else {
        formData.append(`vehicle_signed_cheque[${index}]`, "");
      }
      const remarkField = `${field}_remark`;
      const remark = data[remarkField] || "";
      VsignedChequeRemarks.push(remark);
    });
    formData.append(
      "vehicle_signed_cheque_remark",
      JSON.stringify(VsignedChequeRemarks)
    );

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    // const VehicleDocs = [];
    // if (data.vsignedCheque1 && data.vsignedCheque1.length > 0) {
    //   const file = data.vsignedCheque1[0];
    //   if (file.size > MAX_FILE_SIZE) {
    //     oversizedFiles.push("vsignedCheque1");
    //   } else {
    //     VehicleDocs.push(file);
    //   }
    // }
    // if (data.vsignedCheque2 && data.vsignedCheque2.length > 0) {
    //   const file = data.vsignedCheque2[0];
    //   if (file.size > MAX_FILE_SIZE) {
    //     oversizedFiles.push("vsignedCheque2");
    //   } else {
    //     VehicleDocs.push(file);
    //   }
    // }

    // VehicleDocs.forEach((file, index) => {
    //   formData.append(`vehicle_signed_cheque[${index}]`, file);
    // });

    // if (oversizedFiles.length > 0) {
    //   const fieldNames = oversizedFiles.join(", ");
    //   showToast(
    //     `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
    //     "error"
    //   );
    //   return;
    // }
    try {
      const apiEndpoint = `${baseURL}admin/update-borrower-documents`;
      const method = "post";
      // const response = await axios.post(apiEndpoint, formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      const response = await axios({
        method: method,
        url: apiEndpoint,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && response.data.status) {
        const { status, message } = response.data;
        showToast(message, status);
        setDtt(dtt + 1);
        console.log("dtt updated", dtt);
        reset();
        // QueryClient.invalidateQueries("bankDetailsList");
        //   navigate("/lender-service-provider");
        console.log("Form submitted successfully:", response.data);
      } else {
        console.error("Unexpected response format:", response);
        showToast("Unexpected response format", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        // setErr(message);
        showToast(message, "error");
      } else {
        showToast("An error occurred while submitting the form", "error");
      }
    }
  };

  const getFullUrl = (path) => (path ? `${baseDoc}${path}` : null);

  const signedChequeUrls = doc?.borrower_signed_cheque
    ? doc.borrower_signed_cheque.split(",")
    : [];
  const otherBorrowerDocUrls = doc?.borrower_other_docs
    ? doc.borrower_other_docs.split(",")
    : [];
  const [otherDoc1, setOtherDoc1] = useState(otherBorrowerDocUrls[0] || "");
  const [otherDoc2, setOtherDoc2] = useState(otherBorrowerDocUrls[1] || "");
  const [otherDoc3, setOtherDoc3] = useState(otherBorrowerDocUrls[2] || "");
  const [otherDoc4, setOtherDoc4] = useState(otherBorrowerDocUrls[3] || "");
  const otherVehicleDocUrls = doc?.vehicle_other_docs
    ? doc.vehicle_other_docs.split(",")
    : [];
  const [otherVeh1, setOtherVehDoc1] = useState(otherVehicleDocUrls[0] || "");
  const [otherVeh2, setOtherVehDoc2] = useState(otherVehicleDocUrls[1] || "");
  const [otherVeh3, setOtherVehDoc3] = useState(otherVehicleDocUrls[2] || "");
  const [otherVeh4, setOtherVehDoc4] = useState(otherVehicleDocUrls[3] || "");
  // State variables for each signed
  //otherDoc1 ,otherVeh1
  const [signedCheque1, setSignedCheque1] = useState(signedChequeUrls[0] || "");
  const [signedCheque2, setSignedCheque2] = useState(signedChequeUrls[1] || "");
  const [signedCheque3, setSignedCheque3] = useState(signedChequeUrls[2] || "");
  const [signedCheque4, setSignedCheque4] = useState(signedChequeUrls[3] || "");
  useEffect(() => {
    setSignedCheque1(signedChequeUrls[0] || "");
    setSignedCheque2(signedChequeUrls[1] || "");
    setSignedCheque3(signedChequeUrls[2] || "");
    setSignedCheque4(signedChequeUrls[3] || "");
    setVSignedCheque1(VehicleChequeUrls[0] || "");
    setVSignedCheque2(VehicleChequeUrls[1] || "");
    setOtherDoc1(otherBorrowerDocUrls[0] || "");
    setOtherDoc2(otherBorrowerDocUrls[1] || "");
    setOtherDoc3(otherBorrowerDocUrls[2] || "");
    setOtherDoc4(otherBorrowerDocUrls[3] || "");
    setOtherVehDoc1(otherVehicleDocUrls[0] || "");
    setOtherVehDoc2(otherVehicleDocUrls[1] || "");
    setOtherVehDoc3(otherVehicleDocUrls[2] || "");
    setOtherVehDoc4(otherVehicleDocUrls[3] || "");
  }, [doc]);
  const VehicleChequeUrls = doc?.vehicle_signed_cheque
    ? doc.vehicle_signed_cheque.split(",")
    : [];

  // State variables for each signed cheque
  const [vsignedCheque1, setVSignedCheque1] = useState(
    VehicleChequeUrls[0] || ""
  );
  const [vsignedCheque2, setVSignedCheque2] = useState(
    VehicleChequeUrls[1] || ""
  );

  const fileUrl = getFullUrl(doc?.insurance);
  const isPDF = fileUrl?.toLowerCase().includes(".pdf");
  const BrentUrl = getFullUrl(doc?.borrower_rent_doc);
  const isBrentUrlPdf = BrentUrl?.toLowerCase().includes(".pdf");
  const GrentUrl = getFullUrl(doc?.guarantor_rent_doc);
  const isGrentUrlPdf = GrentUrl?.toLowerCase().includes(".pdf");
  const CrentUrl = getFullUrl(doc?.coapplicant_rent_doc);
  const isCrentUrlPdf = CrentUrl?.toLowerCase().includes(".pdf");
  const BNameUrl = getFullUrl(doc?.borrower_name_declaration);
  const isBNameUrlPdf = BNameUrl?.toLowerCase().includes(".pdf");
  const CNameUrl = getFullUrl(doc?.coapplicant_name_declaration);
  const isCNameUrlPdf = CNameUrl?.toLowerCase().includes(".pdf");
  const GNameUrl = getFullUrl(doc?.guarantor_name_declaration);
  const isGNameUrlPdf = GNameUrl?.toLowerCase().includes(".pdf");
  //BNameUrl,CNameUrl,GNameUrl
  //isBNameUrlPdf,isCNameUrlPdf,isGNameUrlPdf
  console.log("doc", doc);
  console.log("otherDoc1", getFullUrl(otherDoc1));
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={bimg}
        onClick={imgHandle}
      >
        <ZoomImg
          addHandle={imgHandle}
          zoomImgUrl={zoomImgUrl}
          // borrowerId={borrowerId}
        />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div style={{ height: "calc(100vh - 30px)", overflowY: "scroll" }}> */}
        <div style={{ marginBottom: "12px" }}>
          <p className={styles.bldtxtinim}>Borrower Documents</p>
          <div className={styles.totalcheckconnnforimgsec}>
            {/* <div className={styles.fourinputasc}> */}
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Image</label>
              <img
                src={getFullUrl(doc?.borrower_avtar)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.borrower_avtar))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("borrower_avtar")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("borrower_avtar_remark")}
              />
              {errors.borrower_avtar_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Aadhar Front</label>
              <img
                src={getFullUrl(doc?.borrower_aadhar_front)}
                className={styles.imgforedt}
                onClick={() =>
                  imgHandle(getFullUrl(doc?.borrower_aadhar_front))
                }
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("aadhar_front")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("aadhar_front_remark")}
              />
              {errors.aadhar_front_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Aadhar Back</label>
              <img
                src={getFullUrl(doc?.borrower_aahar_back)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.borrower_aahar_back))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("aadhar_back")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("aadhar_back_remark")}
              />
              {errors.aadhar_back_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>PAN Card</label>
              <img
                src={getFullUrl(doc?.borrower_pan_doc)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.borrower_pan_doc))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("pan_doc")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("pan_doc_remark")}
              />
              {errors.pan_doc_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>URC Certificate</label>
              <img
                src={getFullUrl(doc?.urc_certificate)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.urc_certificate))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("urc_certificate")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("urc_certificate_remark")}
              />
              {errors.urc_certificate_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Electricity Bill</label>
              <img
                src={getFullUrl(doc?.borrower_res_address_proof)}
                className={styles.imgforedt}
                onClick={() =>
                  imgHandle(getFullUrl(doc?.borrower_res_address_proof))
                }
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("res_address_proof")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("res_address_proof_remark")}
              />
              {errors.res_address_proof_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Rent Agreement</label>

              {isBrentUrlPdf ? (
                // <button
                //   type="button"
                //   className={styles.viedcbtni}
                //   onClick={() => imgHandle(BrentUrl)}
                // >
                //   View PDF
                // </button>
                <iframe
                  src={BrentUrl}
                  className={styles.pdfPreview}
                  onClick={() => imgHandle(BrentUrl)}
                ></iframe>
              ) : (
                <img
                  src={BrentUrl}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(BrentUrl)}
                />
              )}
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("borrower_rent_doc")}
                accept="image/jpeg, image/png, image/jpg, application/pdf"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("borrower_rent_doc_remark")}
              />
              {errors.borrower_rent_doc_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Name Declaration</label>
              {isBNameUrlPdf ? (
                // <button
                //   type="button"
                //   className={styles.viedcbtni}
                //   onClick={() => imgHandle(BNameUrl)}
                // >
                //   View PDF
                // </button>
                <iframe
                  src={BNameUrl}
                  className={styles.pdfPreview}
                  onClick={() => imgHandle(BNameUrl)}
                ></iframe>
              ) : (
                <img
                  src={BNameUrl}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(BNameUrl)}
                />
              )}

              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("borrower_name_declaration")}
                accept="image/jpeg, image/png, image/jpg, application/pdf"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("borrower_name_declaration_remark")}
              />
              {errors.borrower_name_declaration_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Driving Licence</label>
              <img
                src={getFullUrl(doc?.borrower_driving_license)}
                className={styles.imgforedt}
                onClick={() =>
                  imgHandle(getFullUrl(doc?.borrower_driving_license))
                }
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("driving_license")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("driving_license_remark")}
              />
              {errors.driving_license_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Signed Cheque 1</label>
              <img
                src={getFullUrl(signedCheque1)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(signedCheque1))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("signed_cheque_1")}
                // {...register("borrower_signed_cheque")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("signed_cheque_1_remark")}
              />
              {errors.signed_cheque_1_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Signed Cheque 2</label>
              <img
                src={getFullUrl(signedCheque2)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(signedCheque2))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("signed_cheque_2")}
                //  {...register("borrower_signed_cheque")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("signed_cheque_2_remark")}
              />
              {errors.signed_cheque_2_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Signed Cheque 3</label>
              <img
                src={getFullUrl(signedCheque3)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(signedCheque3))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("signed_cheque_3")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("signed_cheque_3_remark")}
              />
              {errors.signed_cheque_3_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Signed Cheque 4</label>
              <img
                src={getFullUrl(signedCheque4)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(signedCheque4))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("signed_cheque_4")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("signed_cheque_4_remark")}
              />
              {errors.signed_cheque_4_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            {otherDoc1 && (
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>Other Documents 1</label>
                <img
                  src={getFullUrl(otherDoc1)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(otherDoc1))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("otherDoc1")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("otherDoc1_remark")}
                />
                {errors.otherDoc1_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            )}
            {otherDoc2 && (
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>Other Documents 1</label>
                <img
                  src={getFullUrl(otherDoc2)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(otherDoc2))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("otherDoc2")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("otherDoc2_remark")}
                />
                {errors.otherDoc2_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            )}
            {otherDoc3 && (
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>Other Documents 1</label>
                <img
                  src={getFullUrl(otherDoc3)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(otherDoc3))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("otherDoc3")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("otherDoc3_remark")}
                />
                {errors.otherDoc3_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            )}
            {otherDoc4 && (
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>Other Documents 1</label>
                <img
                  src={getFullUrl(otherDoc4)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(otherDoc4))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("otherDoc4")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("otherDoc4_remark")}
                />
                {errors.otherDoc4_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            )}
          </div>
        </div>
        {(doc?.coapplicant_avtar ||
          doc?.coAadhar_front ||
          doc?.coAahar_back ||
          doc?.coPan_doc ||
          doc?.coPerAddress_proof) && (
          <div style={{ marginBottom: "12px" }}>
            <p className={styles.bldtxtinim}>Co-Applicant Documents</p>
            <div className={styles.totalcheckconnnforimgsec}>
              {/* <div className={styles.fourinputasc}> */}
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>Image</label>
                <img
                  src={getFullUrl(doc?.coapplicant_avtar)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(doc?.coapplicant_avtar))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("coapplicant_avtar")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("coapplicant_avtar_remark")}
                />
                {errors.coapplicant_avtar_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>Aadhar Front</label>
                <img
                  src={getFullUrl(doc?.coAadhar_front)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(doc?.coAadhar_front))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("coAadhar_front")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("coAadhar_front_remark")}
                />
                {errors.coAadhar_front_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>Aadhar Back</label>
                <img
                  src={getFullUrl(doc?.coAahar_back)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(doc?.coAahar_back))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("coAadhar_back")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("coAadhar_back_remark")}
                />
                {errors.coAadhar_back_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>{" "}
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>PAN Card</label>
                <img
                  src={getFullUrl(doc?.coPan_doc)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(doc?.coPan_doc))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("coPan_doc")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("coPan_doc_remark")}
                />
                {errors.coPan_doc_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>Electricity Bill</label>
                <img
                  src={getFullUrl(doc?.coResAddress_proof)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(doc?.coPerAddress_proof))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("coResAddress_proof")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("coResAddress_proof_remark")}
                />
                {errors.coResAddress_proof_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>Rent Agreement</label>

                {isCrentUrlPdf ? (
                  // <button
                  //   type="button"
                  //   className={styles.viedcbtni}
                  //   onClick={() => imgHandle(CrentUrl)}
                  // >
                  //   View PDF
                  // </button>
                  <iframe
                    src={CrentUrl}
                    className={styles.pdfPreview}
                    onClick={() => imgHandle(CrentUrl)}
                  ></iframe>
                ) : (
                  <img
                    src={CrentUrl}
                    className={styles.imgforedt}
                    onClick={() => imgHandle(CrentUrl)}
                  />
                )}

                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("coapplicant_rent_doc")}
                  accept="image/jpeg, image/png, image/jpg, application/pdf"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("coapplicant_rent_doc_remark")}
                />
                {errors.coapplicant_rent_doc_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>Name Declaration</label>

                {isCNameUrlPdf ? (
                  // <button
                  //   type="button"
                  //   className={styles.viedcbtni}
                  //   onClick={() => imgHandle(CNameUrl)}
                  // >
                  //   View PDF
                  // </button>
                  <iframe
                    src={CNameUrl}
                    className={styles.pdfPreview}
                    onClick={() => imgHandle(CNameUrl)}
                  ></iframe>
                ) : (
                  <img
                    src={CNameUrl}
                    className={styles.imgforedt}
                    onClick={() => imgHandle(CNameUrl)}
                  />
                )}

                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("coapplicant_name_declaration")}
                  accept="image/jpeg, image/png, image/jpg, application/pdf"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("coapplicant_name_declaration_remark")}
                />
                {errors.coapplicant_name_declaration_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
              {/* // otherDoc1 ,otherVeh1 */}
            </div>
          </div>
        )}
        {/* co-applicant details ends Here*/}
        {/* guarantor details start Here*/}
        <div style={{ marginBottom: "12px" }}>
          <p className={styles.bldtxtinim}>Guarantor Documents</p>
          <div className={styles.totalcheckconnnforimgsec}>
            {/* <div className={styles.fourinputasc}> */}
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Image</label>
              <img
                src={getFullUrl(doc?.guarantor_avtar)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.guarantor_avtar))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("guarantor_avtar")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("guarantor_avtar_remark")}
              />
              {errors.guarantor_avtar_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Aadhar Front</label>
              <img
                src={getFullUrl(doc?.guarantor_aadhar_front)}
                className={styles.imgforedt}
                onClick={() =>
                  imgHandle(getFullUrl(doc?.guarantor_aadhar_front))
                }
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("guarantor_aadhar_front")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("guarantor_aadhar_front_remark")}
              />
              {errors.guarantor_aadhar_front_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Aadhar Back</label>
              <img
                src={getFullUrl(doc?.guarantor_aadhar_back)}
                className={styles.imgforedt}
                onClick={() =>
                  imgHandle(getFullUrl(doc?.guarantor_aadhar_back))
                }
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("guarantor_aadhar_back")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("guarantor_aadhar_back_remark")}
              />
              {errors.guarantor_aadhar_back_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>{" "}
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>PAN Card</label>
              <img
                src={getFullUrl(doc?.guarantor_pan_doc)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.guarantor_pan_doc))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("guarantor_pan_doc")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("guarantor_pan_doc_remark")}
              />
              {errors.guarantor_pan_doc_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Electricity Bill</label>
              <img
                src={getFullUrl(doc?.guarantor_coresaddress_proof)}
                className={styles.imgforedt}
                onClick={() =>
                  imgHandle(getFullUrl(doc?.guarantor_coresaddress_proof))
                }
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("guarantor_coresaddress_proof")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("guarantor_coresaddress_proof_remark")}
              />
              {errors.guarantor_coresaddress_proof_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            {/* isBrentUrlPdf,isGrentUrlPdf,isCrentUrlPdf
            BrentUrl,GrentUrl,CrentUrl
            {isPDF ? (
              <button
                type="button"
                className={styles.viedcbtni}
                onClick={() => imgHandle(fileUrl)}
              >
                View PDF
              </button>
            ) : (
              <img
                src={fileUrl}
                className={styles.imgforedt}
               
                onClick={() => imgHandle(fileUrl)}
              />
            )} */}
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Rent Agreement</label>
              {isGrentUrlPdf ? (
                // <button
                //   type="button"
                //   className={styles.viedcbtni}
                //   onClick={() => imgHandle(GrentUrl)}
                // >
                //   View PDF
                // </button>
                <iframe
                  src={GrentUrl}
                  className={styles.pdfPreview}
                  onClick={() => imgHandle(GrentUrl)}
                ></iframe>
              ) : (
                <img
                  src={GrentUrl}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(GrentUrl)}
                />
              )}
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("guarantor_rent_doc")}
                accept="image/jpeg, image/png, image/jpg, application/pdf"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("guarantor_rent_doc_remark")}
              />
              {errors.guarantor_rent_doc_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Name Declaration</label>

              {isGNameUrlPdf ? (
                // <button
                //   type="button"
                //   className={styles.viedcbtni}
                //   onClick={() => imgHandle(GNameUrl)}
                // >
                //   View PDF
                // </button>
                <iframe
                  src={GNameUrl}
                  className={styles.pdfPreview}
                  onClick={() => imgHandle(GNameUrl)}
                ></iframe>
              ) : (
                <img
                  src={GNameUrl}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(GNameUrl)}
                />
              )}
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("guarantor_name_declaration")}
                accept="image/jpeg, image/png, image/jpg, application/pdf"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("guarantor_name_declaration_remark")}
              />
              {errors.guarantor_name_declaration_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
        </div>
        {/* co-applicant details ends Here*/}
        <div style={{ marginBottom: "12px" }}>
          <p className={styles.bldtxtinim}>Vehicle Document</p>
          <div className={styles.totalcheckconnnforimgsec}>
            {/* <div className={styles.fourinputasc}> */}
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Vehicle Image</label>
              <img
                src={getFullUrl(doc?.vehicle_image)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.vehicle_image))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("vehicle_image")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("vehicle_image_remark")}
              />
              {errors.vehicle_image_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>RTO Document</label>
              <img
                src={getFullUrl(doc?.rto_document)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.rto_document))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("rto_document")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("rto_document_remark")}
              />
              {errors.rto_document_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>{" "}
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>RTO Tax Receipt</label>
              <img
                src={getFullUrl(doc?.rto_tax_receipt)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.rto_tax_receipt))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("rto_tax_receipt")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("rto_tax_receipt_remark")}
              />
              {errors.rto_tax_receipt_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Insurance</label>

              {isPDF ? (
                <iframe
                  src={fileUrl}
                  className={styles.pdfPreview}
                  onClick={() => imgHandle(fileUrl)}
                ></iframe>
              ) : (
                // <button
                //   type="button"
                //   className={styles.viedcbtni}
                //   onClick={() => imgHandle(fileUrl)}
                // >
                //   View PDF
                // </button>
                <img
                  src={fileUrl}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(fileUrl)}
                />
              )}

              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("insurance")}
                accept="image/jpeg, image/png, image/jpg, application/pdf"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("insurance_remark")}
              />
              {errors.insurance_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Invoice</label>
              <img
                src={getFullUrl(doc?.invoice)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.invoice))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("invoice")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("invoice_remark")}
              />
              {errors.invoice_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>MMR</label>
              <img
                src={getFullUrl(doc?.mmr)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.mmr))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("mmr")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("mmr_remark")}
              />
              {errors.mmr_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>DOH</label>
              <img
                src={getFullUrl(doc?.doh)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.doh))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("doh")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("doh_remark")}
              />
              {errors.doh_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Chassis Number</label>
              <img
                src={getFullUrl(doc?.chasis_number)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.chasis_number))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("chasis_number")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("chasis_number_remark")}
              />
              {errors.chasis_number_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Battery Image</label>
              <img
                src={getFullUrl(doc?.battery_image)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.battery_image))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("battery_image")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("battery_image_remark")}
              />
              {errors.battery_image_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Motor Controller</label>
              <img
                src={getFullUrl(doc?.motor_controller)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(doc?.motor_controller))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("motor_controller")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("motor_controller_remark")}
              />
              {errors.motor_controller_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Registration Certificate</label>
              <img
                src={getFullUrl(doc?.registration_certificate)}
                className={styles.imgforedt}
                onClick={() =>
                  imgHandle(getFullUrl(doc?.registration_certificate))
                }
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("registration_certificate")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("registration_certificate_remark")}
              />
              {errors.registration_certificate_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Signed Cheque 5</label>
              <img
                src={getFullUrl(vsignedCheque1)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(vsignedCheque1))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("vsignedCheque1")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("vsignedCheque1_remark")}
              />
              {errors.vsignedCheque1_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinpinimgse}>
              <label className={styles.slbltt}>Signed Cheque 6</label>
              <img
                src={getFullUrl(vsignedCheque2)}
                className={styles.imgforedt}
                onClick={() => imgHandle(getFullUrl(vsignedCheque2))}
              />
              <label className={styles.slbltt}>Replace</label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("vsignedCheque2")}
                accept="image/jpeg, image/png,image/jpg"
              />
              <input
                type="text"
                placeholder="Remarks"
                className={styles.inputstrdest}
                {...register("vsignedCheque2_remark")}
              />
              {errors.vsignedCheque2_remark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            {otherVeh1 && (
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>
                  Other Vehicle Document 1
                </label>
                <img
                  src={getFullUrl(otherVeh1)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(otherVeh1))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("otherVeh1")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("otherVeh1_remark")}
                />
                {errors.otherVeh1_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            )}
            {otherVeh2 && (
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>
                  Other Vehicle Document 2
                </label>
                <img
                  src={getFullUrl(otherVeh2)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(otherVeh2))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("otherVeh2")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("otherVeh2_remark")}
                />
                {errors.otherVeh2_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            )}
            {otherVeh3 && (
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>
                  Other Vehicle Document 3
                </label>
                <img
                  src={getFullUrl(otherVeh3)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(otherVeh3))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("otherVeh3")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("otherVeh3_remark")}
                />
                {errors.otherVeh3_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            )}
            {otherVeh4 && (
              <div className={styles.searorinpinimgse}>
                <label className={styles.slbltt}>
                  Other Vehicle Document 4
                </label>
                <img
                  src={getFullUrl(otherVeh4)}
                  className={styles.imgforedt}
                  onClick={() => imgHandle(getFullUrl(otherVeh4))}
                />
                <label className={styles.slbltt}>Replace</label>
                <input
                  type="file"
                  className={styles.uploadbx}
                  {...register("otherVeh4")}
                  accept="image/jpeg, image/png,image/jpg"
                />
                <input
                  type="text"
                  placeholder="Remarks"
                  className={styles.inputstrdest}
                  {...register("otherVeh4_remark")}
                />
                {errors.otherVeh4_remark && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            )}
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
        <div className={styles.buttonasc} style={{ marginTop: "10px" }}>
          {/* {!defaultValue && ( */}
          <button type="submit" className={styles.Subbtn}>
            Update
          </button>
          {/* )} */}
        </div>
      </form>
    </div>
  );
};

export default DealerForm;
