import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaEye, FaFileDownload, FaSort } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import data from "./data.json";
import RemarkModal from "./RemarkModal";
import AppRejOem from "./AppRejOem";
const OEMTable = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [viewM, setViewM] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [type, setType] = useState(null);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const addViewHandle = () => {
    setViewM((val) => !val);
  };

  const HandleApprove = (id, type) => {
    setRowToEdit(id);
    setType(type);

    setAdd((val) => !val);
  };

  const HandleDownloadDoc = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;

    const fileName = fileUrl.split("/").pop();
    link.download = fileName;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const HandleReject = (id, type) => {
    setRowToEdit(id);

    setAdd((val) => !val);
    setType(type);
  };
  const HandleView = (id) => {
    setRowToEdit(id);

    setViewM((val) => !val);
  };

  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 290px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th style={{ minWidth: "40px" }}>#</th>
                <th style={{ minWidth: "120px" }}>Uploaded By</th>
                <th style={{ minWidth: "120px" }}>Uploaded At</th>
                <th style={{ minWidth: "120px" }}>Status</th>

                <th style={{ minWidth: "120px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* {data?.map((row, index) => ( */}
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.uploaded_by_name}</td>
                  <td>{row.updated_at}</td>
                  <td>{row.status}</td>
                  <td>
                    {" "}
                    {perm?.includes("update") && (
                      <>
                        {row.status === "PENDING" && (
                          <IoCheckmarkCircle
                            title="Approve"
                            size={15}
                            style={{ paddingRight: "7.5px", color: "green" }}
                            onClick={() => HandleApprove(row.id, "approve")}
                          />
                        )}
                      </>
                    )}
                    {perm?.includes("update") && (
                      <>
                        {row.status === "PENDING" && (
                          <IoIosCloseCircle
                            title="Reject"
                            size={15}
                            style={{ color: "red", paddingRight: "7.5px" }}
                            onClick={() => HandleReject(row.id, "reject")}
                          />
                        )}
                      </>
                    )}
                    {row.status !== "PENDING" && (
                      <FaEye
                        title="View Remarks"
                        size={15}
                        style={{ color: "#495057", paddingRight: "7.5px" }}
                        onClick={() => HandleView(row.reason)}
                      />
                    )}
                    {perm?.includes("export") && (
                      <FaFileDownload
                        title="Download Excel"
                        size={15}
                        style={{ color: "#495057", paddingRight: "7.5px" }}
                        onClick={() => HandleDownloadDoc(row.file_path)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AppRejOem
            addHandle={addHandle}
            defaultValue={rowToEdit || ""}
            type={type || ""}
          />
        </Backdrop>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={viewM}
          onClick={addViewHandle}
        >
          <RemarkModal
            addHandle={addViewHandle}
            defaultValue={rowToEdit || ""}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default OEMTable;
