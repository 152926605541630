import React, { useContext } from "react";
import styles from "./Modal.module.css";
import close from "./../../../icons/close.png";
import { AuthContext } from "../../../context/AuthContext";

const ViewImg = ({ zoomImgUrl, addHandle }) => {
  const { baseDoc } = useContext(AuthContext);
  const fullImgUrl = `${baseDoc}${zoomImgUrl}`;
  const isPdf = zoomImgUrl && zoomImgUrl?.endsWith(".pdf");

  return (
    <form
      style={{ minWidth: "400px" }}
      className={styles.modalcontainerr}
      onClick={(event) => event.stopPropagation()}
    >
      <div style={{ textAlign: "right" }}>
        <img
          onClick={addHandle}
          src={close}
          alt="Close"
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            right: "5px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBottom: "1px solid #red", display: "flex", gap: "10px" }}
      >
        {isPdf ? (
          <iframe
            src={fullImgUrl}
            style={{
              width: "600px",
              height: "500px",
              maxHeight: "500px",
              border: "none",
            }}
            title="Document Viewer"
          />
        ) : (
          <img
            src={fullImgUrl}
            alt="Preview"
            style={{ width: "600px", maxHeight: "500px" }}
          />
        )}
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            {/* Add additional buttons or actions here if needed */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ViewImg;
