import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
const IFSCDetails = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm();
  const [{ data: depatmentList }] = useQueries({
    queries: [
      {
        queryKey: ["department", defaultValue],
        queryFn: () =>
          api
            // .get(
            //   `get-ifsc-info/ICIC0000269`
            .get(`get-ifsc-info/${defaultValue?.ifsc_code}`)
            .then((res) => res.data),
        initialData: [],
      },
    ],
  });
  console.log("depatmentList", depatmentList);
  return (
    <form
      className={styles.modalcontainer}
      style={{ width: "520px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "IFSC Details" : "IFSC Details"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform} style={{ width: "300px" }}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              IFSC Code
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {depatmentList?.data?.ifsc}
            </lebel>
          </div>
          <div className={styles.modalform} style={{ width: "300px" }}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              MICR Code
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {depatmentList?.data?.micr}
            </lebel>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform} style={{ width: "300px" }}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Bank
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {depatmentList?.data?.bank}
            </lebel>
          </div>
          <div className={styles.modalform} style={{ width: "300px" }}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Branch
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {depatmentList?.data?.branch}
            </lebel>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform} style={{ width: "300px" }}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              City
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {depatmentList?.data?.city}
            </lebel>
          </div>
          <div className={styles.modalform} style={{ width: "300px" }}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              District
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {depatmentList?.data?.district}
            </lebel>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform} style={{ width: "300px" }}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              State
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {depatmentList?.data?.state}
            </lebel>
          </div>
          <div className={styles.modalform} style={{ width: "300px" }}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Address
            </lebel>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {depatmentList?.data?.address}
            </lebel>
          </div>
        </div>
      </div>
    </form>
  );
};

export default IFSCDetails;
