import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
const AddModal = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm();
  const password = watch("password");
  const [err, setErr] = useState({});
  useEffect(() => {
    if (defaultValue) {
      setValue("beneficiary_name", defaultValue?.beneficiary_name);
      setValue("account_number", defaultValue.account_number);
      setValue("ifsc_code", defaultValue.ifsc_code);
      setValue("micr_code", defaultValue?.micr_code);
    }
  }, [defaultValue, setValue]);
  const onSubmit = async (data) => {
    let payload;
    if (defaultValue) {
      payload = {
        user_type: defaultValue.user_type,
        beneficiary_name: data.beneficiary_name,
        account_number: data.account_number,
        ifsc_code: data.ifsc_code,
        micr_code: data.micr_code,
      };
    } else {
      payload = {
        user_type: defaultValue.user_type,
        beneficiary_name: data.beneficiary_name,
        account_number: data.account_number,
        ifsc_code: data.ifsc_code,
        micr_code: data.micr_code,
      };
    }
    // console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };
  // const updateVpnMutation = useCustomMutation({
  //   url: defaultValue ? `update-user/${defaultValue.id}` : "admin/register",
  //   method: defaultValue ? "put" : "post",
  //   invalidateKey: "userlist",
  //   successCallback: () => {
  //     reset();
  //     // addHandle();
  //   },
  // });
  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `admin/update-beneficiary-details/${defaultValue.id}`
      : `admin/update-beneficiary-details`,
    method: defaultValue ? "post" : "post",
    invalidateKey: "userlist",
    successCallback: () => {
      reset();
      addHandle();
    },
    setErr: setErr,
  });
  console.log("Err", err);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "520px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Edit Beneficiary" : "Add Beneficiary"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Beneficiary Name
            </lebel>
            <input
              placeholder="Enter Name"
              {...register("beneficiary_name", { required: true })}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.beneficiary_name && (
              <span className={styles.error}>This Field is Required.</span>
            )}
            {/* <span className={styles.error}>
              {err?.name ? err?.name[0] : ""}
            </span> */}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Account Number
            </lebel>
            <input
              placeholder="Enter Mobile"
              {...register("account_number", {
                required: true,
                // minLength: {
                //   value: 10,
                //   message: "10 digits Required",
                // },
              })}
              type="number"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.account_number && (
              <span className={styles.error}>
                {errors.account_number.message || "This Field is Missing"}
              </span>
            )}
            {/* <span className={styles.error}>
              {err.mobile ? err.mobile[0] : ""}
            </span> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              IFSC Code
            </lebel>
            <input
              placeholder="Enter IFSC Code"
              {...register("ifsc_code", {
                required: true,
                // minLength: {
                //   value: 10,
                //   message: "10 digits Required",
                // },
              })}
              // {...register("ifsc_code", {
              //   required: "This field is required.",
              //   pattern: {
              //     value: /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/,
              //     message: "Enter a valid email address.",
              //   },
              // })}
              // onChange={(e) => {
              //   const lowercaseValue = e.target.value.toLowerCase();
              //   e.target.value = lowercaseValue;
              // }}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {/* {errors.email && (
              <span className={styles.error}>
                {err.email ? err.email[0] : "This Field is Missing."}
              </span>
            )} */}
            {errors.ifsc_code && (
              <span className={styles.error}>
                {errors.ifsc_code.message || "This Field is Missing"}
              </span>
            )}
            {/* <span className={styles.error}>
              {err.email ? err.email[0] : ""}
            </span> */}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              MICR Code
            </lebel>
            <input
              placeholder="Enter MICR Code"
              {...register("micr_code", {
                required: true,
                // minLength: {
                //   value: 10,
                //   message: "10 digits Required",
                // },
              })}
              type="number"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.micr_code && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
