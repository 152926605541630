import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaEye, FaSort } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import CustomActInBox from "../../Common/Custom Confirm/CustomActInBox";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import RemarkM from "./RemarkM";
import RemarkView from "./RemarkView";
import { IoIosCloseCircle } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";
const LenTable = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [re, setRe] = useState(false);
  const [type, setType] = useState(null);
  const [viewM, setViewM] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const addViewHandle = () => {
    setViewM((val) => !val);
  };
  const reHandle = () => {
    setRe((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const HandleApprove = (id, type) => {
    setRowToEdit(id);
    setType(type);

    setRe((val) => !val);
  };
  const HandleReject = (id, type) => {
    setRowToEdit(id);

    setRe((val) => !val);
    setType(type);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const HandleView = (id) => {
    setRowToEdit(id);

    setViewM((val) => !val);
  };
  const deleteConfirmHandler = () => {
    console.log("selectedData", selectedItem);
    updateDelMutation.mutate(selectedItem);
    handleClose();
  };
  const updateDelMutation = useCustomMutation({
    url: `admin/change-admin-user-status/${selectedItem?.id}`,
    method: "post",
    invalidateKey: "langList",
    // setErr: setErr,
    successCallback: () => {
      // reset();
      // addHandle();
    },
  });
  const deleteHandler = (data) => {
    setSelectedItem(data);
    handleClickOpen();
    console.log(data);
  };
  console.log("rowToEdit", rowToEdit);
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      {" "}
      {open && (
        <CustomActInBox
          data={selectedItem}
          onCancel={handleClose}
          onConfirm={deleteConfirmHandler}
          message={`Are you sure you want to ${
            selectedItem.is_active ? "deactivate" : "activate"
          } ${selectedItem.name}`}
          open={open}
        />
      )}
      <div className={styles.tabwrap}>
        {" "}
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Borrower ID</th>

                <th>Status</th>
                <th>Approved / Rejected By</th>
                <th>Updated At</th>
                <th>Created At</th>
                <th> Admin Remark</th>
                <th> Lender Messege</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.borrower_id}</td>
                  <td>{row.status}</td>
                  <td>{row.admin_user_name}</td>
                  <td>{row.updated_at}</td>
                  <td>{row.created_at}</td>

                  <td>
                    {row.admin_remarks && (
                      <button
                        type="button"
                        title="Admin Remarks"
                        onClick={() => HandleView(row?.admin_remarks)}
                        className={styles.viewbtn}
                      >
                        <FaEye
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}

                    {/* <button
                      type="button"
                      className={styles.dltbtn}
                      style={{ width: "180px" }}
                    >
                     
                      Request Download Access
                    </button> */}
                  </td>

                  <td>
                    {row.lender_message && (
                      <button
                        title="Lender Messege"
                        type="button"
                        onClick={() => HandleView(row?.lender_message)}
                        className={styles.editbtn}
                      >
                        <FaEye
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    {/* {row.lender_message && (
                        <FaEye
                          size={12}
                          title="Lender Messege"
                          onClick={() => HandleView(row?.lender_message)}
                          style={{
                            position: "relative",
                            top: "1px",
                            color: "blue",
                          }}
                        />
                      )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={re}
          onClick={reHandle}
        >
          <RemarkM
            addHandle={reHandle}
            defaultValue={rowToEdit || ""}
            type={type || ""}
          />
        </Backdrop>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={viewM}
          onClick={addViewHandle}
        >
          <RemarkView
            addHandle={addViewHandle}
            defaultValue={rowToEdit || ""}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default LenTable;
