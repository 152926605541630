import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import api from "../../Common/api";
import { useQueries, useQuery } from "@tanstack/react-query";

const AddModal = ({ addHandle, defaultValue }) => {
  const [existingData, setExistingData] = useState(null);
  const fetchData = async () => {
    const response = await api.get(`edit-dealer/${defaultValue.dealer_id}`);
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList", defaultValue],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;

  return (
    <div
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Dealer Details</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          className={styles.modalform}
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "0.7rem",
            flexDirection: "row",
          }}
        >
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Dealer Name
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {existingData?.name || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Mobile Number
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {existingData?.mobile || "-"}
            </label>
          </div>
        </div>
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          className={styles.modalform}
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "0.7rem",
            flexDirection: "row",
          }}
        >
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Email
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {existingData?.email || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              City
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              {existingData?.city || "-"}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
