import React, { useContext, useEffect } from "react";
import styles from "../CreateDealer/BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useNavigate } from "react-router-dom";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import ListWithSearc from "../../Common/ListWithSearch/ListWithSearc";
import { useToast } from "../../../context/ToastProvder";
import { AuthContext } from "../../../context/AuthContext";
import { Backdrop } from "@mui/material";
const AddSubD = ({ defaultValue }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const BorrowerID = watch("borrower_id");
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { token, baseURL } = useContext(AuthContext);

  const [{ data: borrowerList }, { data: dealerDet }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("admin/get-borrower-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["subDeta", BorrowerID],
        queryFn: () =>
          api
            .get(`admin/get-borrower-details-for-subvention/${BorrowerID}`)
            .then((res) => res.data),
        initialData: [],
        enabled: !!BorrowerID,
      },
    ],
  });
  useEffect(() => {
    if (dealerDet && !defaultValue) {
      setValue("subvention_amount", dealerDet?.data?.subvention_amount);
    }
  }, [setValue, dealerDet]);
  useEffect(() => {
    if (!defaultValue) {
      const requiredFields = ["payment_receipt_img"];

      requiredFields.forEach((field) => {
        register(field, { required: true });
      });
    }
  }, [register, defaultValue]);
  useEffect(() => {
    if (defaultValue) {
      setValue("borrower_id", defaultValue?.borrower_id);
      setValue("utr_number", defaultValue?.utr_number);
      setValue("subvention_amount", defaultValue?.subvention_amount);
      const formattedDate = defaultValue?.payment_date
        ? defaultValue.payment_date.split("-").reverse().join("-")
        : "";
      setValue("payment_date", formattedDate);
      setValue("bank_name", defaultValue?.bank_name);
    }
  }, [defaultValue, setValue, borrowerList]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("borrower_id", data.borrower_id);
    formData.append("subvention_amount", data.subvention_amount);
    formData.append("utr_number", data.utr_number);
    formData.append("payment_date", data.payment_date);
    formData.append("bank_name", data.bank_name);
    const fileFields = ["payment_receipt_img"];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const oversizedFiles = [];
    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }

    try {
      const apiEndpoint = defaultValue
        ? `${baseURL}admin/update-subvention-detail/${defaultValue?.id}`
        : `${baseURL}admin/store-subvention-detail`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      navigate("/subvention-details-list");
    } catch (error) {
      const { message, errors } = error.response.data;
      showToast(message, "error");
    }
  };

  return (
    <div style={{ marginBottom: "15px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          {dealerDet?.data && (
            <div
              className={styles.totalcheckconnn}
              style={{
                border: "1px solid #ddd",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Dealer ID
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.dealer_id}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Dealer Name
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.dealer_name}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Dealer City
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.dealer_city}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Product Battery Type
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.product_battery_type}
                </label>
              </div>
            </div>
          )}

          <div className={styles.totalcheckconnn}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Borrower<span className={styles.requ}>*</span>
              </label>

              <Controller
                control={control}
                rules={{ required: "This Field is Missing" }}
                render={({ field: { onChange, value } }) => (
                  <ListWithSearc
                    options={borrowerList?.data || []}
                    getOptionLabel={(val) => val.borrower_id || ""}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={option.id}
                      >{`${option.borrower_id}`}</li>
                    )}
                    className={styles.listSrch}
                    onChange={(event, selectedValue) => {
                      onChange(selectedValue ? selectedValue?.id : null);
                    }}
                    value={
                      borrowerList?.data?.find(
                        (employee) => employee.id === value
                      ) || null
                    }
                  />
                )}
                name={`borrower_id`}
              />

              {errors.borrower_id && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Subvention Amount <span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("subvention_amount", {
                  required: true,
                })}
              />

              {errors.subvention_amount && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                UTR Number <span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.01"
                className={styles.inpttsc}
                {...register("utr_number", {
                  required: true,
                })}
              />

              {errors.utr_number && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Payment Date<span className={styles.requ}>*</span>
              </label>
              <input
                type="date"
                className={styles.inpttsc}
                {...register("payment_date", {
                  required: true,
                })}
              />

              {errors.payment_date && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Bank Name<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("bank_name", {
                  required: true,
                })}
              />

              {errors.bank_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Payment Receipt Image <span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                accept="image/jpeg, image/png,image/jpg"
                {...register("payment_receipt_img")}
              />

              {errors.payment_receipt_img && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>

          <div className={styles.buttonasc}>
            <button
              type="submit"
              className={styles.Subbtn}
              style={{
                position: "relative",
                bottom: "10px",
              }}
            >
              {defaultValue ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSubD;
