import React, { useContext, useState } from "react";
import styles from "../Borrower/Language.module.css";
import { Controller, useForm } from "react-hook-form";

import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { AuthContext } from "../../../context/AuthContext";
import { Backdrop } from "@mui/material";
import ErrModal from "./ErrModal";
const ExcelForm = ({ setUpd, upd, type }) => {
  const [err, setErr] = useState([]);
  const [add, setAdd] = useState(false);
  const { token, baseURL } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append file fields
    const fileFields = ["file"];

    fileFields.forEach((field) => {
      if (data[field] && data[field].length > 0) {
        formData.append(field, data[field][0]);
      }
    });
    const apiUrl =
      type === "oem"
        ? `${baseURL}admin/upload-oem-product-excel`
        : `${baseURL}admin/upload-product-excel`;
    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      // const response = await axios.post(
      //   `${baseURL}admin/upload-product-excel`,

      //   formData,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      const { status, message } = response?.data;
      showToast(message, status);

      setUpd(upd + 1);
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
      const { message, errors, data } = error?.response?.data;
      setErr(data?.errors);
      showToast(message, "error");
      addHandle();
    }

    // try {
    //   const response = await fetch(
    //     "https://insta.finayo.tech/api/store-dealer",
    //     {
    //       method: "POST",
    //       body: formData,
    //     }
    //   );

    //   if (response.ok) {
    //     console.log("Files uploaded successfully");
    //   } else {
    //     console.error("File upload failed", response.statusText);
    //     const responseData = await response.json();
    //     console.error("Response Data:", responseData);
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", gap: "10px" }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <input
            type="file"
            className={styles.uploadbx}
            {...register("file", { required: true })}
            accept=".xlsx"
            style={{ border: "1px solid #ddd", borderRadius: "10px" }}
          />
          {errors.file && (
            <span className={styles.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <button
        type="submit"
        className={styles.Subbtn}
        style={{
          width: "105px",
          maxHeight: "25px",
          padding: "5px",
          borderRadius: "100px",
        }}
      >
        {" "}
        Upload
      </button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ErrModal addHandle={addHandle} err={err || []} />
      </Backdrop>
    </form>
  );
};

export default ExcelForm;
