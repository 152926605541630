import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import api from "../../Common/api";
import { QueryClient, useQueries } from "@tanstack/react-query";
import { useToast } from "../../../context/ToastProvder";
const AddModal = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();
  useEffect(() => {
    if (defaultValue) {
      setValue("language", defaultValue.department);
    }
  }, [defaultValue, setValue]);

  console.log("defaultValue", defaultValue);
  const onSubmit = async (data) => {
    let payload;
    payload = {
      department: data.language,
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `update-user-department/${defaultValue.id}`
      : "store-user-department",
    method: defaultValue ? "put" : "post",
    invalidateKey: "langList",
    successCallback: () => {
      reset();
      addHandle();
    },
  });
  const [{ data: depatmentList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const { showToast } = useToast();
  // const onSubmit = async (data) => {
  //   let payload;
  //   payload = {
  //     department: data.language,
  //   };

  //   console.log("Payload to be submitted:", payload);
  //   const url = defaultValue
  //     ? `update-user-department/${defaultValue.id}`
  //     : "store-user-department";
  //   const method = defaultValue ? "put" : "post";
  //   try {
  //     const response = await api[method](url, payload);
  //     const { status, message } = response.data;
  //     console.log("response.data", response.data);
  //     if (response.status === 200 || response.data.status === "success") {
  //       console.log("API call successful:", response.data);

  //       showToast(message, status);
  //       reset();
  //       addHandle();
  //       // QueryClient.invalidateQueries("langList");
  //     }
  //   } catch (error) {
  //     const { message, data: errorDetails } = error.response.data;
  //     showToast(message, "error");
  //     console.error(
  //       "Error while submitting data:",
  //       error.response?.data || error.message
  //     );
  //   }
  // };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Edit Department" : "Add Department"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div className={styles.modalform}>
          <lebel
            style={{
              marginBottom: "0.5rem",
              color: "#494646",
              fontSize: "13px",
            }}
          >
            Department
          </lebel>
          <input
            placeholder="Enter Department"
            {...register("language", { required: true })}
            type="text"
            className={styles.mdlinput}
          />

          {errors.language && (
            <span className={styles.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
