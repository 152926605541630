import React from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useToast } from "../../../context/ToastProvder";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch, control } = useForm();
  // const onSubmit = (data) => {
  //   console.log("searchForm submitted:", data);
  //   onSearch(data);
  // };
  const [{ data: BranchList }, { data: lspList }, { data: dealerList }] =
    useQueries({
      queries: [
        {
          queryKey: ["branchlist"],
          queryFn: () =>
            api
              .get("admin/get-branch-location-screener")
              .then((res) => res.data),
          initialData: [],
        },
        {
          queryKey: ["department"],
          queryFn: () =>
            api.get("admin/get-lsp-screener").then((res) => res.data),
          initialData: [],
        },

        {
          queryKey: ["dealers"],
          queryFn: () => api.get("dealer-screener").then((res) => res.data),
          initialData: [],
        },
      ],
    });

  const { showToast } = useToast();
  const onSubmit = (data) => {
    const { start_date, end_date } = data;

    // Check if end date is selected without a start date
    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }

    if (data.pan && data.pan.length !== 10) {
      showToast("Invalid PAN Number.");
      return;
    }
    if (data.aadhar && data.aadhar.length !== 12) {
      showToast("Invalid Aadhar Number.");
      return;
    }
    console.log("searchForm submitted:", data);
    onSearch(data);
  };

  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Start Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("start_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              End Date
            </label>

            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("end_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Name
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("name")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("mobile")}
            />
          </div>
        </div>
        <div
          className={styles.fourinputasc}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          {" "}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Alternative Mobile Number
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("alternative_number")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("pan")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Aadhar
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("aadhar")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Branch
            </label>
            <select
              {...register("branch_id")}
              className={styles.inpttsc}
              style={{ height: "30px" }}
            >
              <option value="">--Select Branch--</option>
              {BranchList?.data?.map((row, i) => (
                <option key={i} value={row.id}>
                  {row.location_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          className={styles.twoinputasc}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          {" "}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer
            </label>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <ListWithSearch
                  options={dealerList?.data || []}
                  getOptionLabel={(val) => val.name || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>{`${option.name}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    dealerList?.data?.find(
                      (employee) => employee.id === value
                    ) || null
                  }
                />
              )}
              name={`dealer_id`}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              LSP
            </label>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <ListWithSearch
                  options={lspList?.data || []}
                  getOptionLabel={(val) => val.name || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>{`${option.name}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    lspList?.data?.find((employee) => employee.id === value) ||
                    null
                  }
                />
              )}
              name={`lsp_id`}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
