import React, { useContext, useState } from "react";
import styles from "../Borrower/Language.module.css";
import { Controller, useForm } from "react-hook-form";

import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { AuthContext } from "../../../context/AuthContext";
import { Backdrop } from "@mui/material";
import ErrModal from "./ErrModal";
import { FaDownload } from "react-icons/fa";
import api from "../../Common/api";
const ExcelForm = ({ setUpd, upd, perm }) => {
  const [err, setErr] = useState([]);

  const [add, setAdd] = useState(false);
  const { token, baseURL, baseDoc } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append file fields
    const fileFields = ["file"];

    fileFields.forEach((field) => {
      if (data[field] && data[field].length > 0) {
        formData.append(field, data[field][0]);
      }
    });

    try {
      const response = await axios.post(
        `${baseURL}admin/upload-disbursement-excel`,
        // "https://insta.finayo.tech/api/admin/upload-product-excel",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = response.data;
      showToast(message, status);

      setUpd(upd + 1);
      reset();
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
      const { message, errors, data } = error.response.data;
      setErr(data?.errors);
      showToast(message, "error");
      addHandle();
    }
  };

  const handleDownloadForm = async () => {
    try {
      const response = await api.get("admin/get-disbursement-excel-template");

      if (response.data.status === "success") {
        const downloadLink = response.data.data.download_link;

        // Create an anchor element to trigger the download
        const link = document.createElement("a");
        link.href = downloadLink;
        link.download = downloadLink.split("/").pop(); // Extracts filename from the URL
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link element
        document.body.removeChild(link);
      } else {
        console.error("Failed to retrieve the template URL.");
        showToast("Failed to retrieve the file URL", "error");
      }
    } catch (error) {
      console.error("Error fetching download link:", error);
      showToast("Failed to download the form", "error");
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", gap: "10px" }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <input
            type="file"
            className={styles.uploadbx}
            {...register("file", { required: true })}
            accept=".xlsx"
            style={{
              borderRadius: "10px",
              border: "1px solid #ddd",
              marginTop: "3px",
            }}
          />
          {errors.file && (
            <span className={styles.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <button
        type="submit"
        style={{ width: "100px" }}
        className={styles.Subbtn}
      >
        {" "}
        Upload
      </button>
      {perm?.includes("export") && (
        <button
          type="button"
          onClick={handleDownloadForm}
          className={styles.Subbtn}
          style={{ width: "165px" }}
        >
          <FaDownload size="10px" /> Download Sample
        </button>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ErrModal addHandle={addHandle} err={err || []} />
      </Backdrop>
    </form>
  );
};

export default ExcelForm;
