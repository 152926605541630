import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaEye, FaSort } from "react-icons/fa";
import {
  MdDelete,
  MdOutlineAddCircleOutline,
  MdPlaylistAddCheckCircle,
} from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
import AddCharges from "./AddCharges";
import ViewCharges from "./ViewCharges";
import RemarkM from "./RemarkM";
import RemarkView from "../Borrower/RemarkView";

const OEMProducts = ({ rowData, page, numberOfRecords, perm, type }) => {
  const [add, setAdd] = useState(false);
  const [viewM, setViewM] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [re, setRe] = useState(false);
  const [char, setChar] = useState(false);
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const reHandle = () => {
    setRe((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const HandleView = (id) => {
    setRowToEdit(id);

    setViewM((val) => !val);
  };
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };
  const HandleApprove = (id, type) => {
    setRowToEdit(id);

    setSelectedItem(id);

    setRe((val) => !val);
  };
  const addViewHandle = () => {
    setViewM((val) => !val);
  };
  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const navigate = useNavigate();
  const handleEditRow = (val) => {
    navigate("/add-oem-product", {
      state: { val },
    });
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#12468a",
                backgroundImage:
                  "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 20%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th style={{ minWidth: "40px" }}>#</th>
                <th>OEM</th>
                <th>OEM Product ID</th>

                <th style={{ minWidth: "120px" }}>Product Type</th>
                <th style={{ minWidth: "120px" }}>Product Name</th>

                <th style={{ minWidth: "120px" }}>Battery Type</th>

                <th style={{ minWidth: "120px" }}>Ex-showroom Price</th>
                <th style={{ minWidth: "120px" }}>Loan to Value Ratio</th>
                <th style={{ minWidth: "120px" }}>Min Loan Amount</th>
                <th style={{ minWidth: "120px" }}>Max Loan Amount</th>
                <th style={{ minWidth: "120px" }}>Min Loan Tenure</th>
                <th style={{ minWidth: "120px" }}>Max Loan Tenure</th>
                <th style={{ minWidth: "120px" }}>Status</th>
                <th style={{ minWidth: "120px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.manufacturer?.manufacturer}</td>
                  <td>{row.oem_product_unq}</td>
                  <td>{row.product_type}</td>
                  <td>{row.product_name}</td>

                  <td>{row.battery_type}</td>

                  <td>{row.ex_showroom_price}</td>
                  <td>{row.loan_to_value_ratio}</td>
                  <td>{row.min_loan_amount}</td>
                  <td>{row.max_loan_amount}</td>
                  <td>{row.min_loan_tenure}</td>
                  <td>{row.max_loan_tenure}</td>
                  {perm?.includes("update") ? (
                    <td style={{ maxWidth: "90px" }}>
                      {row?.status === "active" ? (
                        <button
                          // title="Inactivate Status"
                          type="button"
                          onClick={() => HandleApprove(row, "approve")}
                          // onClick={() => deleteHandler(row)}
                          style={{ minWidth: "70px" }}
                          className={styles.accessbtn}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          type="button"
                          // title="Activate Status"
                          onClick={() => HandleApprove(row, "reject")}
                          // onClick={() => deleteHandler(row)}
                          className={styles.accessbtnnegativ}
                          style={{ minWidth: "70px" }}
                        >
                          Inactive
                        </button>
                      )}
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>
                    {" "}
                    {perm?.includes("update") && (
                      <button
                        title="Edit"
                        type="button"
                        onClick={() => handleEditRow(row)}
                        className={styles.editbtn}
                      >
                        <AiFillEdit
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    {row.admin_remarks && (
                      <button
                        type="button"
                        title="Remark"
                        onClick={() => HandleView(row)}
                        className={styles.viewbtn}
                      >
                        <FaEye
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={re}
        onClick={reHandle}
      >
        <RemarkM
          addHandle={reHandle}
          defaultValue={rowToEdit || ""}
          type={type || ""}
        />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viewM}
        onClick={addViewHandle}
      >
        <RemarkView addHandle={addViewHandle} defaultValue={rowToEdit || ""} />
      </Backdrop>
    </div>
  );
};

export default OEMProducts;
