import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Language.module.css";
import Thankyou from "../../../icons/Thnaks.png";
import AdditionalElev from "./AdditionalElev";
import AdditionalTwel from "./AdditionalTwel";
import { useToast } from "../../../context/ToastProvder";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
import { AuthContext } from "../../../context/AuthContext";
const StepFifthee = () => {
  const { borrId, userId, userD } = useContext(LoanContext);
  const { showToast } = useToast();
  const [productId, setProductId] = useState(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { ver } = useContext(AuthContext);
  const HandleShow = () => {
    navigate("/apply-loan-application");
  };
  const HandleSkipbtn = () => {
    setShow(true);
  };
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          //  console.log("Loan Status:", response.data);

          const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          setProductId(product_id);
          if (product_id === "4") {
            handleSkip();
          }
        })
        .catch((error) => {
          //  console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  const handleSkip = async () => {
    const payload = {
      borrower_id: borrId,
      skip_iot: "YES",
    };

    try {
      const response = await api.post("admin/skip-iot-step", payload);
      // Assuming the response has a success status or message
      if (response.data.status === "success") {
        showToast("Successfully skipped IOT Step", "success");
        setShow(true);
        // handleNext();
        // setActiveStep(5);
      } else {
        showToast("Failed to skip IOT Step", "error");
      }
    } catch (error) {
      // console.error("Error skipping co-applicant:", error);
      const { message, errors } = error.response.data;
      // console.log("message", message);
      showToast(
        message || "An error occurred while skipping IOT Step",
        "error"
      );
    }
  };
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const payload = {
      borrower_id: borrId,
      vehicle_id: data.vehicle_id,
      user_id: userId || userD?.id,
    };

    try {
      const response = await api.post(
        `${ver}admin-check-iot-activation`,
        payload
      );
      // const response = await api.post(`${ver}check-iot-activation`, payload);
      const { status, message } = response.data;
      // console.log("response.data", response.data);
      if (response.status === 200 || response.data.status === "success") {
        //   console.log("API call successful:", response.data);

        // showToast(message, status);
        setShow(true);
      }
    } catch (error) {
      const { message, data: errorDetails } = error.response.data;
      showToast(message, "error");
    }
  };
  return (
    <div>
      {!show && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={classes.applheader}>GPS LOCATION IOT DEVICE</p>
          <div className={classes.totalcheckconnn}>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Vehicle ID<span className={classes.requ}>*</span>
              </label>
              <input
                {...register("vehicle_id", { required: true })}
                type="text"
                className={classes.inputstst}
              />
              {errors.vehicle_id && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
              {/* <span className={classes.error}>NOT ACTIVATED</span> */}
            </div>
          </div>
          <div
            className={classes.buttonasc}
            style={{ marginTop: "10px", textAlign: "left" }}
          >
            {/* {!defaultValue && ( */}
            <button
              type="submit"
              className={classes.Subbtn}
              style={{ width: "80px", marginRight: "12px" }}
            >
              Activate
            </button>
            <button
              type="button"
              className={classes.Subbtn}
              style={{ width: "80px", background: "rgb(198, 19, 27)" }}
              onClick={HandleSkipbtn}
            >
              Skip
            </button>
          </div>
        </form>
      )}
      {show && (
        <div>
          <img src={Thankyou} style={{ width: "300px" }} />
          <p className={classes.thnktt}>
            Thanks For Submitting Your Application.
          </p>

          <button
            type="button"
            className={classes.Subbtn}
            style={{ width: "100px" }}
            onClick={HandleShow}
          >
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default StepFifthee;
