import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classes from "./Sidenav.module.css";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import Submenu from "./Submenu";
import { useAuth } from "../../../context/AuthContext";

const Menu = ({ menu, Mkey }) => {
  const [showMenu, setShowMenu] = useState(false);
  // const [activeSubmenu, setActiveSubmenu] = useState(null);
  const { activeSubmenuId, setActiveSubmenuId } = useAuth();
  const location = useLocation();
  //   const dispatch = useDispatch();

  //   const openedMenu = useSelector((state) => state.menuTrack.openedMenu);
  const toggleMenu = (e) => {
    e.stopPropagation();
    setShowMenu((prevShowMenu) => {
      const newShowMenu = !prevShowMenu;
      // if (prevShowMenu) {
      //   setActiveSubmenu(null);
      // }
      //   if (newShowMenu) {
      //     dispatch(menuTrackActions.setOpenMenu(menu.menuid));
      //   }
      //  else {
      //   dispatch(menuTrackActions.setOpenMenu(menu.menuid));
      // }
      return newShowMenu;
    });
  };
  // const handleSubmenuClick = (submenuId) => {
  //   setActiveSubmenu(submenuId);
  // };
  const handleSubmenuClick = (submenuId) => {
    setActiveSubmenuId(submenuId);
  };

  // useEffect(() => {
  //   if (openedMenu !== menu.menuid) {
  //     setShowMenu(false);
  //   }
  // }, [openedMenu, menu.menuid]);
  return (
    <li key={menu.id}>
      {menu.submenus && menu?.submenus?.length > 0 ? (
        <div className={classes.submenune} onClick={toggleMenu}>
          <span
            style={{
              textShadow: showMenu ? "rgb(0 0 0 / 80%) 0px 5px 15px" : "none",
            }}
            className={`${showMenu ? classes.activeicon : ""} ${classes.icon}`}
          >
            {menu?.name}
          </span>
          {showMenu ? (
            <IoIosArrowDown
              className={classes.icntb}
              style={{ width: "10px", color: "#cacddc" }}
            />
          ) : (
            <IoIosArrowForward
              className={classes.icntb}
              style={{ width: "10px", color: "#cacddc" }}
            />
          )}
        </div>
      ) : (
        <NavLink
          to={menu.path}
          style={{ textDecoration: "none" }}
          className={`${showMenu ? classes.activesubmenune : ""} ${
            classes.submenune
          }`}
        >
          <span className={classes.icon}>{menu.name}</span>
        </NavLink>
      )}
      {showMenu && menu?.submenus && (
        <ul className={classes.menuList}>
          {menu?.submenus?.map((subitem) => (
            <Submenu
              submenu={subitem}
              key={subitem.id}
              isActive={activeSubmenuId === subitem.id}
              // isActive={activeSubmenu === subitem.id}
              onClick={() => handleSubmenuClick(subitem.id)}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default Menu;
