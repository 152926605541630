import React, { useState, useEffect } from "react";
import styles from "./Modal.module.css";
import close from "./../../../icons/close.png";

const ViewImg = ({ zoomImgUrl, addHandle }) => {
  const [normalizedUrl, setNormalizedUrl] = useState("");

  // Normalize the URL only if it's a valid string
  useEffect(() => {
    if (typeof zoomImgUrl === "string" && zoomImgUrl) {
      // Replace .PDF with .pdf (case-insensitive)
      const updatedUrl = zoomImgUrl.replace(/\.PDF$/i, ".pdf");
      setNormalizedUrl(updatedUrl);
    } else {
      setNormalizedUrl(""); // Reset to empty string if it's not a valid string
    }
  }, [zoomImgUrl]);

  // Check if the file is a PDF
  const isPdf = (url) =>
    typeof url === "string" && url.toLowerCase().endsWith(".pdf");

  // Debugging logs
  console.log("Original URL:", zoomImgUrl);
  console.log("Normalized URL:", normalizedUrl);

  return (
    <form
      style={{ minWidth: "400px" }}
      className={styles.modalcontainerr}
      onClick={(event) => event.stopPropagation()}
    >
      {/* Close button */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            height: "11px",
            position: "relative",
            top: "3px",
            right: "5px",
            cursor: "pointer",
          }}
          alt="Close"
        />
      </div>

      {/* Main content */}
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid red", display: "flex", gap: "10px" }}
      >
        {/* Render PDF in iframe or image based on file type */}
        {isPdf(normalizedUrl) ? (
          <iframe
            src={normalizedUrl}
            style={{ width: "600px", height: "500px" }}
            title="PDF Preview"
          />
        ) : (
          <img
            src={normalizedUrl}
            style={{ width: "600px", maxHeight: "500px" }}
            alt="Zoomed Document"
          />
        )}
      </div>

      {/* Footer */}
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
        </div>
      </div>
    </form>
  );
};

export default ViewImg;
