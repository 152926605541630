import React from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
import { useToast } from "../../../context/ToastProvder";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
const SearchComp = ({ onSearch, type }) => {
  const { register, handleSubmit, reset, watch, control } = useForm();
  const { showToast } = useToast();
  const [{ data: lenderList }] = useQueries({
    queries: [
      {
        queryKey: ["userType"],
        queryFn: () => api.get("admin/lender-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });

  const onSubmit = (data) => {
    const { start_date, end_date } = data;

    // Check if end date is selected without a start date
    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }
    console.log("searchForm submitted:", data);
    onSearch(data);
  };
  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const currentDate = new Date().toISOString().split("T")[0];
  const [{ data: departmentList }] = useQueries({
    queries: [
      {
        queryKey: ["branchlist"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Start Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("start_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              End Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("end_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Borrower ID
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("borrower_id")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Status
            </label>

            <select
              {...register("status")}
              className={styles.inpttsc}
              style={{ height: "29px" }}
            >
              <option value="">--Select Status--</option>
              <option value="PENDING">PENDING</option>
              <option value="APPROVED">APPROVED</option>
              <option value="REJECTED">REJECTED</option>
            </select>
          </div>
        </div>
        {type && (
          <div
            className={styles.oneinputads}
            style={{ gap: "20px", marginTop: "10px" }}
          >
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Lender
              </label>

              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ListWithSearch
                    options={lenderList?.data || []}
                    getOptionLabel={(val) => val.name || ""}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>{`${option.name}`}</li>
                    )}
                    className={styles.listSrch}
                    onChange={(event, selectedValue) => {
                      onChange(selectedValue ? selectedValue?.id : null);
                    }}
                    value={
                      lenderList?.data?.find(
                        (employee) => employee.id === value
                      ) || null
                    }
                  />
                )}
                name={`lender_id`}
              />
            </div>
          </div>
        )}
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
